.createTripUsers {
	margin-bottom: 1rem;
}



.tripAddedUsers {
	display: flex;
	margin-left: 4rem;
	flex-wrap: wrap;
	margin-bottom: 1rem;
} 

.createTripContainer .addTripUsers {
	display: flex;
	justify-content: space-between;

}

.tripContainer .addTripUsers {
	width: 100%;
	display: flex;
	justify-content: center;
}

.createTripUsers .textTitle {
	margin-right: 1rem;
	text-align: center;
}


.tripContainer .createTripUserInput {
	margin-bottom: 0.5rem;

}

.tripContainer .createUsersButtons {
	display: flex;
	justify-content: space-around;
	margin-top: auto;
}

.tripContainer .createTripUsersContent {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-self: center;
}
