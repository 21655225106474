.tripUsersHeader {
	display: flex;
	justify-content: space-between;
}

.tripUsersButtons {
	display: flex;
	align-items: center;
}

.tripUserInfo {
	display: flex;
	align-items: center;
}


.tripUserName {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
}

