 .resumeMobileBackground {
   background-image: url('./van.png');
   background-size: cover;
   height: 50rem;
}

.resumeDesktopBackground {
   background-image: url('./van.png');
   background-size: cover;
   height: 100vh;
}

.resumeDesktopHolder {
   display: flex;
   padding:  4rem;
   padding-bottom: 0;

}

.topHolder {
   padding-top: 3rem;
   display: flex;
   flex-direction: column;
   align-items: center;
}


.textCenter {
   align-self: center;
   text-align: center;
   color: #18191F;
   width:  80%;
   font-weight: 500;
   font-family: Inter;
}

.leftHolder {
   width:  50%;
   margin-top:  1rem;
}

.logoIconLarge {
   height:  5rem;
}

.textLeft {
   font-size: 20px;
   padding-left: 1rem;
   color: #18191F;
}

.rightHolder {
   width:  50%;
   position: relative;
}

.circles {
   width: 100%;
   margin-left: 10%;
   margin-top: -5rem;
}
.textRightHolder {
   top:  0;
   left:  0;
}

.textRight {
  z-index: 2;
  position: absolute;
  top: 12%;
  left: 48%;
}

.resumeHeaderText {
    font-size: 2.5rem;
    font-family: Inter;
    font-weight: 600;
    color: #18191F;
    line-height: 3.5rem;
}

.resumeList {
   margin-left: -1rem;
}

.resumeListText {
   font-family:  Inter;
   color: #18191F;
   line-height: 1.8rem;
}

.resumeListTextSmall {
   font-family:  Inter;
   color: #18191F;
   font-size: 12px;
}

.resumeLink {
   color:  #105B57;
   font-weight: 600;
   font-family:  Inter;

}

.resumeOneLine: {
   display: flex;
}

.resumeEmailPass {
   margin-left: 1rem;
   color: #BFE2DC;
}

.iphoneImagesHolder {
   display: flex;
   z-index: 10;
   margin-top: 6rem;
   width: 120%;
}

.iphoneImage {
   width: 30%;
   z-index: 10;
}


.horizontalImagesHolder {
    overflow: auto;
   white-space: nowrap;
   margin-top: 2rem;
   margin-left: 1rem;
   margin-bottom: 1rem;
}

.iphoneImageMobile {
   width: 60%;
}

.bottomHolder {
   padding: 1rem;
   background-color: #BFE2DC;
   justify-content: center;
   align-items: center;
   display: flex;
   flex-direction: column;
}