.locationTagHolder {
    height: 1.2rem;
    margin-bottom: 0.5rem;
}

.message {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
}

.messageHeader {
	display: flex;
}


.messageReceived {
	color: black;
}

.allMessages {
	width: 100%;
}

.allAndUserMessages .allMessages {
	width: 30%;
}