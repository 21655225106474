.groupedAttractionsHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}


.overallLocationContainer .dropdownSection {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.savedLocationContainer .groupedAttractionsContent {
	margin-top: 0.5rem;
}

.groupedHeaderButtons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
	padding-right: 0.5rem;
}

/*make the tabs smaller to create space between since rendering at 50% of screen*/
.tripLocAttrItinerary .tabButtons {
	padding-right: 1rem;
	padding-left: 1rem;
}

.tripLocAttrItinerary .tabButton {
	width: 7rem;
}

.attractionsTable {
	overflow-y: scroll;
	height: 20rem;
	width: 100%;
}

.groupedAttraction {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 0.5rem;
}

.groupedAttractionIcons {
	display: flex;
	align-items: center;
}

.groupedAttractionLeft {
	display: flex;
	align-items: center;
}

.groupedAttrName {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	display: flex;
	flex-wrap: wrap;
}

.groupedAttractionsList .removeIcon {
	align-self: flex-start;
	margin-right: 0.5rem;
}
