.locationHeader {
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	margin-left: 2rem;
}

.locHeaderLeft {
	width: 70%;
	display: flex;
}

.locHeaderContent {
	display: flex;
	width: 100%;
	margin-left: 1rem;
	flex-direction: column;
	justify-content: center;
}

.locHeaderMain {
	display: flex;
	flex-direction: row;
}


.locHeaderRight {
	margin-left: 1rem;
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.headerMiddle {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}

.headerMiddleMain {
	width: 70%;
}




