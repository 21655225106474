.pictures .mainBackground.picturesContent {
	border-radius: 20px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
}

.picturesRow {
	display: flex;
	overflow-x: auto;
}

.attractionPictures {
	margin-bottom: 1rem;
}

.pictures .iconLarge {
	margin-left: 0.5rem;
	margin-bottom: 0.5rem;
}