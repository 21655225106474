.userMessages {
	height: 28rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}


.userMessagesContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;
}

/*with react scroll to bottom, my messages wouldnt display on right side without holder*/
.messageHolder{
	display: flex;
}

.myMessageHolder {
	justify-content: flex-end;
}