.suggestedFollow {
	margin-top: 1rem;
}

.suggestedFollowContent {
	display: flex;
	overflow: scroll;
}

.suggestedFollow {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 8rem;
	
}

.suggestedFollowHolder {
	margin-right: 0.5rem;
}

.suggestedFollows .textLink {
	margin-top: 0.2rem; 
	margin-bottom: 0.2rem;
}

