.notificationContent, 
.notificationContent:hover {
	display: flex;
	color: black;
	align-items: center;
	text-decoration: none;
}

.notificationMain {
	display: flex;
	align-items: center;
}


.notificationMiddle {
	margin-left: 1rem;
}

.navBarContainer .notification {
	font-size: 0.7rem;
}

.notificationText {
	margin-right: 0.2rem;

}

.notificationContainer .picMedium {
	margin-left: 2rem;
}

.notificationsContainer .squarePic {
	margin-left: 2rem;
}

.followButtons {
	display: flex;
    width: 10rem;
    justify-content: space-between;
    margin-left: 2rem;
    align-items: center;
}

