.addToTripIcon {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 0.3rem;
}

.addToTripLabel {
	font-size: 0.5rem;
	text-align: center;
	width: 3rem;
}

.attractionContainer .addToTripLabel {
	font-size: 0.7rem;
	width: 3.5rem;
}
