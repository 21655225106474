
.userResult .searchResultLeft {
	display: flex;
}

.searchOverallLeft {
	margin-left: 5rem;
}

.searchResultRight {
	display: flex;
    flex-direction: column;
    align-items: center;
}

.attractionResult .searchResultRight {
	width: 30%;
	display: flex;
}

.searchResultBottom {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.searchResultLeftTop{
	display: flex;
}

.searchResultContent {
	display: flex;
	justify-content: space-between;
}

.attractionResult .searchResultRight .icons {
	margin-bottom: 0.5rem;
    width: 100%;
    justify-content: flex-end;
}

.searchResult .picMedium {
	margin-right: 1rem;
}

.searchResults .stars {
	margin-top: 0.3rem;
}

.starsHolder {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 0.5rem;
}

.searchResultHeaderMiddle {
	display: flex;
	align-items: center;
}

.searchResultHeaderMiddle .icons {
	margin-right: 0.2rem;
}

.searchResultHeaderMiddle .stars {
	margin-left: 0.5rem;
	margin-top: 0rem;
	display: flex;
	align-items: center;
}

