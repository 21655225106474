* {
	color: #707070;
}


/*Main Container Layout*/
.attractionContainer, 
.locationContainer, 
.overallLocationContainer, 
.overallAttractionContainer, 
.savedContainer, 
.tripsContainer, 
.searchContainer, 
.savedLocationContainer, 
.notificationsContainer.fullScreen, 
.tripLocationContainer, 
.tripContainer, 
.tripItineraryContainer, 
.allMessagesContainer, 
.settingsContainer, 
.profileContainer, 
.exploreContainer, 
.addContainer {
	margin-right: 10%;
	margin-left: 10%;
	margin-bottom: 3%;
	overflow: hidden;
	padding-top: 1.2rem;
}

/*Dropdown overlay*/
.navBarContainer .notificationsContainer, 
.navBarContainer .allMessagesContainer {
	z-index: 3;
	background-color: white;
	position: absolute;
	margin-left: 55%;
	display: flex;
	flex-direction: column;
	box-shadow: 5px 5px 10px lightgrey;
	width: 30rem;
	max-height: 50rem;
	margin-top: 0.2rem;
	padding: 0.5rem;
	overflow-y: scroll;
}

.router {
	background-color: rgb(192,192,192, 0.1)
}

.allMessagesRight {
	width: 70%;
	margin-left: 1rem;
}

.attractionContainerContent, 
.messagesContent, 
.attractionsContent, 
.overallAttractionContent, 
.overallLocContent, 
.savedLocationContent, 
.searchContent, 
.tripContent, 
.tripLocationsContent, 
.tripLocAttrOverview, 
.tripLocItinerary {
	display: flex;
}

.attractionLeft, 
.attractionsLeft, 
.overallAttractionLeft, 
.overallLocLeft, 
.savedLocationLeft, 
.searchLeft, 
.tripLeft, 
.tripLocationLeft, 
.tripLocAttrOverview .tripLocAttrLeft, 
.tripLocationsLeft {
	width: 65%;
	margin-right: 1rem;
}

.attractionRight,  
.attractionsRight, 
.overallAttractionRight, 
.overallLocRight, 
.savedLocationRight, 
.searchRight, 
.tripRight, 
.tripLocAttrRight, 
.tripLocationsRight {
	width: 35%;
}


.tripLocItineraryLeft {
	width: 50%;
	margin-right: 1rem;
}

.tripLocItineraryRight {
	width: 50%;
}



/*initial screen layout*/
.initialScreen {
	display: flex;
}


.initialScreenLeft {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	margin-top: 0rem;
	width: 50%;
	flex-direction: column;
	align-items: center;
	display: flex;
}

.mainHeader.initialHeader {
	font-size: 3rem;
}

.secondaryHeader.initialHeader, 
.currentCity .secondaryHeader, 
.signupContainer .singlePicAdd .secondaryHeader {
	margin-top: 7rem;
}

.initialContent {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.initialScreenRight {
	width: 50%;
}

.initialPicture {
	height: 100vh;
	width: 100%;
	object-fit: cover;
}

.initialItem {
	margin: 1rem;
}

.initialItems {
	width: 70%;
	display: flex; 
	flex-direction: column;
	justify-content: center;
	align-items: center; 
}




/*Background*/
.mainBackground {
	padding: 0.5rem 1rem;
	width: 100%;
	font-size: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(254, 223, 73, 0.4);
	border-radius: 10px;
} 



.secondaryBackground {
	background-color: rgba(51, 204, 204, 0.5); 
	border-radius: 30px;
    display: inline-block;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: auto;
}


.mainBackgroundColor {
	background-color: rgba(254, 223, 73, 0.4);
}

.secondaryBackgroundColor {
	background-color: rgba(51, 204, 204, 0.5);
} 



/*Buttons*/
.primaryButton {
/*	height: 2.2rem;*/
	color: white;
	background: #19A59B;
	border-radius: 2rem;
	border: none;
	box-shadow: 5px 5px 10px lightgrey;
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.primaryButton:hover {
	background-color: grey;
}

.primaryButton:focus {
	outline: none;
}

.secondaryButton {
/*	height: 2rem;*/
	color: #19A59B;
	background: white;
	border-radius: 2rem;
	border: none;
	box-shadow: 5px 5px 10px darkgrey;
	padding: 0.5rem; 
	display: flex;
	justify-content: center;
	align-items: center;
}

.secondaryButton:hover {
	background-color: lightgrey;
}

.secondaryButton:focus {
	outline: none;
}

.secondaryButton:disabled, 
.primaryButton:disabled {
	opacity: 0.5
}

.tertiaryButton {
	background-color: grey;
	color: white;
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;

}

.groupedButton {
	margin-right: 1rem;
}






.toggleButtons {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 1rem;
	justify-content: space-around;
	margin-bottom: 1rem;
}


.toggleLeft {
	box-shadow: 0px 5px 10px grey;
}

.toggleRight {
	box-shadow: 5px 5px 10px grey;
}

.toggleButton {
	padding: 0.25rem;
    width: 40%;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
	color: #33CCCC;
	cursor: pointer;
	padding: 0.5rem;
	height: 2rem;
	border-radius: 1rem;
	align-items: center; 
	background-color: #F7F7F7;

}

.toggleSelected {
	background-color: #33CCCC;
    color: white;
}

.dropdownSelect {
	background-color: white;
	width: 100%;

}

.dropdownSection {
	width: 100%;
}

.formToggle {
	display: flex;
	align-items: center;
}

.formToggleSwitch {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.formToggleText {
	white-space: nowrap;
}

.tabButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
}

.tabButton {
	background-color: white;
	color: #33CCCC;
    width: 8rem;
    display: flex;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
    height: 1.7rem;
    box-shadow: -3px -3px 8px grey;
    cursor: pointer;


}

.paginationButtons {
	display: flex;
	justify-content: center;
}

.paginationButton {
	background-color: white;
	box-shadow: -3px -3px 8px grey;
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    cursor: pointer;
}

.paginationButtonLink, 
.paginationButtonLink:hover {
	all: unset;
}

.tabSelected, 
.paginationSelected {
	background-color: #33CCCC;
	color: white;
}

.voteSelected {
	background-color: #19A59B;
	color: white;
}

.voteNotSelected {
	background-color: white;
	box-shadow: -3px -3px 8px grey;


}

.checkboxTouching {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%; 
}

.checkboxLarge {
	transform: scale(1.5);
	margin: 0.5rem;
}

.checkboxLabel {
	margin-right: 0.5rem; 
}

.checkboxSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}


.dropdown {
	width: 15%;
	display: flex;
	justify-content: flex-end;
	margin-right: 0.5rem;
}



.dropdownToggle {
	all: unset;
	font-size: 3rem;
	margin-top: -2rem;
}

.dropdownToggle:hover {
	all:unset;
	font-size: 3rem;
	margin-top: -2rem;
}


.dropdownToggle:active {
	all:unset;
	font-size: 3rem;
	margin-top: -2rem;
	background-color: white;
	border: none;
	color: black;
}

.dropdownToggle:not(:disabled):not(.disabled):active {
	all:unset;
	font-size: 3rem;
	margin-top: -2rem;
	background-color: white;
	border: none;
	color: black;
}

.dropdownToggle:not(:disabled):not(.disabled):active:focus {
	box-shadow: none;
}

.dropdownToggle:focus {
	all:unset;
	font-size: 3rem;
	margin-top: -2rem;
}


/*Headers*/
.headerAndBack {
	display: flex;
}

.mainHeader {
	font-size: 2rem;
	font-weight: 700;
	padding-right: 0.7rem;
	margin-bottom: 0.2rem;
}

.headerUnderlineHolder {
	display: flex;
}

/*.fullHeaderHolder {
	margin-right: auto;
}
*/

.headerUnderline {
	height: 1rem; 
	margin-top: -1rem; 
	margin-left: 0.5rem; 
	background-color: #F9993A; 
	border-radius: 0.4rem; 
}

.subHeaderUnderline {
	height: 0.7rem; 
	margin-top: -0.75rem; 
	margin-left: 0.5rem; 
	background-color: #F9993A; 
	border-radius: 0.4rem; 
}

.categoryHeaderUnderline {
	height: 0.8rem; 
	margin-top: -0.8rem; 
	margin-left: 0.4rem; 
	background-color: #FEDF49; 
	border-radius: 0.4rem; 
}

.mainHeaderUnderline {
	height: 1rem; 
	margin-top: -1.35rem; 
	margin-left: 0.5rem; 
	background-color: #FEDF49; 
	border-radius: 0.4rem; 
	margin-bottom: 1rem;
}

.initialHeaderUnderline {
	background-color: #FEDF49; 
	margin-top: -1.9rem; 
	height: 1.4rem;
	margin-left: 0.7rem;
}

.headerHolder {
	display: inline-block;

}

.partialHeaderHolder {
	margin-right: auto;
}


.initialHeaderHolder {
	margin-bottom: 1rem;
}



.secondaryHeader, 
.secondarHeader:hover {
	font-size: 1.5rem;
	line-height: 2.3rem;
	font-weight: 500;
	margin-bottom: 0.2rem;
}

/*.secondaryHeaderLink, 
.secondaryHeaderLink:hover {
	color: #009656;
	font-size: 1.9rem;
	line-height: 2rem;
	font-weight: 500;
	margin-bottom: 0.1rem;
}*/

/*.secondaryHeaderLink:hover {
	text-decoration: underline;
}*/

.tertiaryHeader {
	font-size: 1.2rem;
}

.subHeader {
	font-size: 1.3rem;
    font-weight: 700;
    margin-left: 0.2rem;
    padding-right: 0.5rem;
}

.secondarySubHeader {
	font-size: 1.1rem;
	font-weight: 500;
}

.tertiarySubHeader {
	font-size: 1rem;
}

.categoryHeader {
	/*color: #476B4D;*/
	font-weight: 500;
	font-size: 1.4rem;
	margin-left: 0.2rem;
	padding-right: 0.4rem;
}



/*Text*/


.secondTextColor {
	/*color: #476B4D;*/
}

.categoryTitle {
	/*color: #476B4D;*/
	font-weight: 600;
	margin-right: 0.4rem;
}

.textTitle {
	font-weight: bold;
	margin-right: 0.2rem;
	white-space: nowrap;
	display: flex;
}

.textTitleForm {
	color: #19A59B;
}

.inlineLink {
	font-weight: bold;
	margin-right: 0.2rem;
	margin-left: 0.2rem;
}

.fontLarge, 
.fontLarge:hover {
	font-size: 1.2rem;
}

.fontXL, 
.fontXL:hover {
	font-size: 1.5rem;
}

.fontSmall, 
.fontSmall:hover {
	font-size: 0.8rem;
}

.fontXS, 
.fontXS:hover {
	font-size: 0.6rem;
}

.buttonSmall {
	font-size: 0.8rem;
    text-align: center;
    padding: 0.2rem;
}

.readMoreText {
	all: unset;
}

.userInputText {
	white-space: pre-wrap;
}

.textLink, 
.textLink:hover, 
.readMoreText, 
.readMoreText:hover {
	color: #19A59B;
	cursor: pointer;
}

.textLink:hover, 
.readMoreText:hover {
	text-decoration: underline;
	cursor: pointer;
}

.createdAt {
	font-size: 0.7rem;
	color: grey;
	align-self: center;
	margin-right: 0.5rem;
}

.required {
	color: #476B4D;
	margin-left: 0.2rem;
}


/*Pop up*/
.popup {
	background-color: white;
	border-radius: 10px;
	box-shadow: 5px 5px 10px lightgrey;
	padding: 1rem;
	z-index: 4;
	position: absolute;
	margin-top: 2%;
	pointer-events: all;
	width: 50%;
	height: 70%;
	margin-left: 15%;
	margin-right: 15%;
}

.createAccountCallout.popup, 
.uploadCallout.popup {
	margin-left: 30%;
}



.popupScroll {
	overflow-y: scroll;
}

/*.popupSmall {
	width: 40%;
	height: 20rem;
	margin-left: 20%;
	margin-right: 20%;

}

.popupMedium {
	width: 50%;
	height: 25rem;
	margin-left: 15%;
	margin-right: 15%;
}

.popupLarge {
	width: 50%;
	height: 34rem;
	margin-left: 15%;
	margin-right: 15%;
}

.popupXL {
	width: 50%;
	height: 38rem;
	margin-left: 15%;
	margin-right: 15%;
}*/

.popupContent {
	margin-top: 1rem;
	width: 100%;
	height: 90%;
	display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
}

/*/*Popup Layout*/

/*popup renders directly from container*/
.locationContainer .pictureAdd, 
.profileContainer .locationTipsForm, 
.attractionContainer .attractionDetails, 
.attractionContainer .pictureAdd, 
.locationListView, 
.locationDetails, 
.followView,
.profileContainer .singlePicAdd, 
.addLocation, 
.uploadProfPic, 
.tripsContainer .tripForm {
	margin-top: 1.5rem;
}

/* renders relative to parent */
.allPicturesView, 
.pictureView,
.addAttraction {
	position: absolute;
	top: 5.5rem;
}


/*popup will show relative to screen because it doesnt always render from same place*/
.addToTrip, 
.deletion, 
.datesForm,
.locationContainer .locationTipsForm, 
.notificationsContainer .pictureView, 
.tripContainer .createTripUsers, 
.tripTransportationForm, 
.itineraryItemForm, 
.ungroupLocations, 
.locationContainer .attractionDetails, 
.locationContainer .addAttraction {
	position: fixed;
	top: 5.5rem;
	left: 10%;
}

.tripTransportationForm, 
.itineraryItemForm {
	top: 4rem;
}


/*always rendered from same spot but rendered within route container*/
.groupTripLocations, 
.addTripLocation {
	margin-top: -3rem;
}

.addTripAttraction {
	margin-top: -5rem;
}



.attractionContainer .googlePictures.allPicturesView {
	margin-top: 15rem;
}

/*center non form popups*/
.addLocationContainer .popup, 
.addAttractionContainer .popup, 
.tripLocationsContainer .popup, 
.tripLocAttractionsContainer .popup {
	justify-content: center;
}

/*Popup in view*/
.disabledBackground { 
	pointer-events: none;
 }

/*Images*/
.picXS {
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 100%;
}

.picSmall{
	height: 2rem;
	width: 2rem;
	border-radius: 100%;
}

.picMedium {
	height: 4rem;
	width: 4rem;
	border-radius: 100%;
}

.picLarge {
	border-radius: 100%;
	height: 10rem;
	width: 10rem;
}

.examplePic {
	width: 50%; 
}

.examplePicHolder {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.squarePic {
	border-radius: 10px; 
	object-fit: cover;
}

.boxPic {
	width: 80%;
	object-fit: contain;
}
		


/*Icons*/
.activeIcon {
	color: #19A59B;
	cursor: pointer;
}

.inactiveIcon {
	color: #19A59B;
	cursor: pointer;
	opacity: 0.2;
}

.nonClickableIcon {
	color: #F9993A;
	opacity: 0.6;
} 

.nonClickableInactiveIcon {
	color: #F9993A; 
	opacity: 0.2;
}

.instaIcon {
	height: 1.8rem;
	width: 1.8rem;
}



.notificationDot {
	height: 0.5rem;
	width: 0.5rem;
	background-color: red;
	border-radius: 50%;
	margin-bottom: -1rem;
	margin-left: -0.25rem;
}

.followerDot {
	margin-bottom: 0;
	margin-top: 0;
}

.progressDot {
	height: 2rem; 
	width: 2rem; 
	border-radius: 100%; 
	border: 1px solid #19A59B; 
	margin: 0.3rem;
} 

.progressDotFilled { 
	background-color: #19A59B; 
} 

.progressDots {
	margin-top: 2rem; 
	margin-bottom: 1rem;
	display: flex; 
	flex-direction: row; 
	justify-content: center; 
	align-items: center;
}


/*Form*/

.formHeader {
	margin-bottom: 1rem;
}

.formField {
	border: 1px solid lightgrey;
	border-radius: 1rem;
	width: 100%;
	padding: 0.2rem;
}

.formField::placeholder {
  color: lightgrey;
}

.formContent {
	width: 100%;
}

.formAdditionalText {
	width: 70%;
}

.shortForm {
	height: 1rem;
}


/*.popupXL .form {
	height: 32rem;
}

.popupLarge .form {
	height: 23rem;
}

.popupMedium .form {
	height: 18rem;
}


.popupSmall .form {
	height: 13rem;
}*/




.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;
	width: 70%;
}

.autocomplete .form {
	width: 100%;
}

.formItem {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.autocompleteField {
	width: 100%;
	border-radius: 1rem;
}

.autocompleteHolder {
	width: 100%;
	height: 4rem;
}

.signupContainer .formButtons {
	margin-top: 2rem;
}

.signupContainer .locationsSignup .formButtons {
	margin-top: -2rem;
}

.formButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: auto;
    margin-bottom: 1rem;
}

.formButton {
	width: 40%;
}

.singleFormButton {
	width: 100%;
	margin-bottom: 1rem;
}

.topButton {
	margin-bottom: 1rem;
}

/*
.singleFormButton,
.signup .formButtons, 
.passwordReset .formButtons, 
.passwordResetEmail .formButtons, 
.initialScreen.basicInfo .formButtons, 
.myProfHeaderButtons {
	justify-content: center;
}*/

/*Other Layout*/

/*map to display with main content*/
.savedLocationContainer .savedLocationRight {
	margin-top: 3.7rem;
}

.tripContainer .tripLocationsRight {
	margin-top: 2.8rem;
}

.attractionsContainer .attractionsRight {
	margin-top: 2.5rem;
}

.attractionContainer .attractionRight {
	margin-top: 2.1rem;
}

.overallLocationContainer .overallLocRight, 
.tripLocationContainer .tripLocAttrRight {
	margin-top: 4.5rem;
}

.attractionInfo {
	display: flex;
	margin-bottom: 0.2rem;
}

.icons, 
.labelAndTooltip, 
.removePicHolder,
.filterStars, 
.backAndHeader, 
.titleAndText {
	display: flex;
	align-items: center;
} 

.headerAndIcons {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 0.5rem;
}

.fullWidth {
	width: 100%;
}

.marginBottom {
	margin-bottom: 1rem;
}

.marginLeft {
	margin-left: 1rem;
}

.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.centerText {
	text-align: center;
}

.closeIconHolder {
	display: flex;
	justify-content: flex-end;
}

.restaurantIcons {
	white-space: nowrap;
}

.backIcon {
	margin-right: 0.5rem;
}

/*.tipTitle {
	width: 12rem;
}*/

.headerAndIcons .icons, 
.removePicHolder .fontXS {
	margin-left: 0.5rem;
}

.attractionBottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.attractionTop {
	display: flex; 
}

.pinAndNumber {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.userInfo, 
.timeframe {
	display: flex;
	align-items: center;
	margin-bottom: 0.3rem;
}

.userName {
	margin-left: 0.3rem;
}


.selectDropdown {
	padding-top: 0.5rem;
    max-height: 5rem;
    overflow-y: scroll;
    font-size: 0.8rem;
    padding: 0.3rem;
}

/*Search*/

.searchForm {
	display: flex;
}

.searchField {
	height: 1.5rem;
	width: 20rem;
	margin-right: 0.5rem;
}

.searchLeft {
	display: flex;
	flex-direction: column;
}

.searchToggleView {
	height: 0.8rem;
    display: flex;
    line-height: 0.8rem;
    margin-top: 0.2rem;
    font-size: 0.8rem;
    align-self: flex-end;
}
   
.searchButton {
	height: 2rem;
	white-space: nowrap;
	padding: 0.2rem;
	margin-right: 1rem;
} 

.switchTitle {
	margin-right: 0.5rem;
}

/*Messages*/
.messageContent {
	margin-left: 0.5rem;
}

.messageHeader {
	display: flex;
	margin-bottom: 0.2rem;
	align-items: center;
}

.messengerName {
	margin-right: 0.5rem;
}

.messageCreatedAt {
	margin-right: 1rem;
}


.locationTagHolder, 
.userTagHolder {
	display: flex;
	margin-right: 0.5rem;
}


.locationTagHolder .removeIcon, 
.userTagHolder .removeIcon {
	margin-left: 0.1rem;
}


.locationTag, 
.userTag, {
	background-color: #F9993A;
	opacity: 0.6;
	box-shadow: 5px 5px 5px grey;
	border-radius: 20px;
	padding: 0 0.2rem;
	display: inline-block;
/*	height: 1rem;*/
}

.attractionTagHolder {
	display: flex;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}


.attractionTag,
.pictureTag {
	background-color: #F9993A;
	opacity: 0.6;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    color: white;
    display: inline-block;
}

.createPictureTag .mainBackground {
	background-color: rgba(254, 223, 73, 0.4);
}

.pictureTag {
	display: flex;
}

.instagramAttribution {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
	font-size: 0.6rem;
}

.nameAndIcon {
	display: flex;
	align-items: center;
}

.loadingHolder {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.attractionTagHolder .removeIcon {
	margin-left: 0.1rem;
}


.attractionTagSmall {
	background-color: #F9993A;
	opacity: 0.6;
    border-radius: 5px;
    color: white;
	font-size: 0.7rem;
    padding: 0.1rem 0.2rem;
    display: inline-block;
    margin-left: 0.3rem;
}

.attractionTagsContent {
	display: flex;
	margin-bottom: 0.5rem;
}

.tooltip {
	all: unset;
	font-size: 0.8rem;
	padding: 0.2rem;
	border-radius: 5px;
	background-color: lightgreen;
	z-index: 5;
	/*position: absolute;*/
}

.pictureView .tooltipIcon, 
.generalLocTips .tooltipIcon {
   	margin-left: 0.3rem;
 }

label {
	all: unset;
}

.disabledPagination {
	opacity: 0;
}

.error, 
.formError {
	color: red;
}

.unread {
	color: #19A59B;
	font-weight: bold;
}

.textInput,
.filterInput {
	margin-bottom: 1rem;
	width: 100%;
	padding: 0.2rem;
	border-radius: 
}

.stars {
	white-space: nowrap;
}


.overallAttractionContainer .addToTripContainer {
	margin-left: 5rem;
}