.commentIcons {
	align-self: center;
}

.commentHeader, 
.commentsHeaderTop, 
.commentsHeaderBottom {
	display: flex;
	align-items: center;
}

.commentsContainer .textLink {
	margin-right: 0.5rem;
}


.edited {
	font-size: 0.7rem;
	color: grey;
	align-self: center;
}

.pictureView .comments {
	margin-top: 1rem;
}


.pictureView .comments {
	height: 25rem;
	overflow-y: scroll;
}

.loginMessage {
	margin-bottom: 0.5rem;
}