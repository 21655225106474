.mainBackground.visitorsContent {
	padding: 0.5rem;
	overflow-y: scroll;
	height: 7rem;
	margin-bottom: 0.25rem;
}

.attractionsContainer .popup .attractionVisitor {
	width: 100%;
}

/*.attractionVisitor {
	width: 60%;
	overflow: hidden;
	white-space: nowrap;
}
*/
.attractionsContainer .attractionVisitor {
	margin-right: 0.2rem;
}


.visitors .picSmall {
	margin-right: 0.5rem;
}

.visitor {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	overflow: hidden;
}

.attractionsContainer .popup .visitorName {
	font-size: 1.5rem;
}

.attractionsContainer .popup .starSmall {
	height: 1.5rem; 
	width: 1.5rem;
}

.attractionsContainer .popup .visitorStars {
	width: 100%;
	margin-left: 2rem;
}

.attractionsContainer .popup .attractionVisitor {
	overflow: initial;
}


.attractionContainer .visitor {
	justify-content: space-between;
}


