.datesContent {
	display: flex;
	align-items: center;
}

.datesContent .iconSmall {
	margin-left: 0.2rem;
}

.date {
	display: flex;
}

.dateContent {
	white-space: nowrap;
	display: flex;
	align-items: flex-end;
}

.startDateAndDash {
	display: flex;
}

.dash {
	vertical-align: center;
}

.dates {
	margin-right: 5rem;
}

.itineraryItemForm .dates {
	margin-top: 0.5rem;
}