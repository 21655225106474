
.searchFilters {
	width: 100%;	
	margin-bottom: 1rem;
}

.filtersContent {
	display: flex;
	justify-content: center;
}

.searchFiltersCollapsed {
	height: 12rem;
}

.filtersToggle {
	display: flex;
	align-items: center;
}

.filtersLabel {
	margin-right: 0.5rem;
}


.filterSection {
	margin-right: 1rem;
	display: flex;
    flex-direction: column;
    align-items: center;
}

/*without height and width, it won't wrap properly. NEED TO MAINTAIN PROPER HEIGHT BASED ON NUMBER OF TAGS*/
.tagSection {
	flex-flow: wrap column;
    display: flex;
    height: 7rem;
    width: 30rem;
}

.tagSectionCollapsed {
	
}

.tagTitle {
	display: flex;
	justify-content: center;
}

.tagSection .checkboxSection {
	width: unset;
	margin-right: 1rem;
}

.filterSection .textTitle {
	display: flex;
	justify-content: center;
	margin-bottom: 0.5rem
}

.andUpSection {
	justify-content: center;
}

.andUpSection .checkboxMedium {
	margin-right: 0.5rem;
}

.toggleFilterHolder {
	width: 100%;
	display: flex; 
	justify-content: center;
	margin-bottom: 1rem;
}