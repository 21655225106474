.cropper-view-box {
	border-radius: 100%;
	outline: none;
}

.point-ne, 
.point-nw, 
.point-se, 
.point-sw {
	display: none;
}

.point-e, 
.point-n, 
.point-w, 
.point-s {
	background-color: black;
}


.cropper-bg {
	background-image: none;
/*	height: 15rem;
	width: 15rem;*/

}
/*
.cropper-crop-box {
	height: 15rem;
	width: 15rem;
}*/

.cropper-modal {
	background-color: white;
}

.picUploading {
	display: none;
}

