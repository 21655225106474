
.autocompleteField {
	margin-bottom: 12rem;
}

.tripLocAttractionsContainer .autocomplete .dropdownSection {
	margin-top: -12rem;
}

.basicInfo .autocomplete .form {
	height: 4rem;

}

.addContainer .dropdownSelect {
	margin-bottom: 3rem;
}
