

.tipSection {
	margin-bottom: 0.5rem;
}

.locationTipsHeader {
	display: flex;
	align-items: center;
}

.locationTipsHeader .subHeader {
	margin-right: 1rem;
}