/*scroll is not ideal but this is a huge form and it renders fixed*/
.tripTransportationForm.popupXL {
	height: 38rem;
	overflow-y: scroll;
}

.addTripUser {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 1rem;
}

.tripTransportationForm .textTitle {
	margin-right: 1rem;
}

.userTagHolder {
	margin-bottom: 0.5rem;
}