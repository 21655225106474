.exploreLocations {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.exploreLocation {
	margin: 1.5rem;
	border-radius: 20px;
	height: 20rem;
	width: 20rem;
	margin-bottom: 2rem;
}

.exploreLink {
	display: flex;
	justify-content: center;
}

.explorePicture {
	height: 20rem;
	width: 20rem;
	object-fit: cover;
	border-radius: 0.8rem;
}

/*.exploreContent {
	display: flex;
	position: absolute;
	margin-top: -2.5rem;
	height: 2.5rem;
	background-color: lightgrey;
	justify-content: space-between;
	width: 20rem;
	opacity: 0.8;
	border-radius: 0 0 20px 20px;
	align-items: center;
	padding: 0 0.5rem;
}*/

.exploreContent { 
 	width: 110%;
    margin-top: -1rem;
    padding-top: 1.5rem;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    background-color: #FEDF49;
    justify-content: space-between;
    margin-left: -5%;
    margin-right: 0.5rem;
    border-radius: 0.8rem;
    align-items: center;
}

.exploreUserName {
	font-size: 0.6rem;
	white-space: nowrap;
}

.exploreUserName:hover {
	font-size: 0.6rem;
	white-space: nowrap;
	color: white;
}

.exploreLocationName {
	font-size: 0.8rem;
	font-weight: bold;

}

.exploreLocationName:hover {
	font-size: 0.8rem;
	font-weight: bold;
	color: white;

}