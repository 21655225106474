.tripLocations .mainBackground {
	height: 18rem;
    overflow-y: scroll;
}

.tripLocationsHeader {
	margin-bottom: 0.5rem;
	display: flex;
	justify-content: space-between;
}

.tripLocation {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}

.tripLocationsTitle {
	display: flex; 
	justify-content: flex-end;
	margin-right: 4rem;
}

.locNameAndRemove {
	display: flex;
}

.tripLocations .removeIcon {
	align-self: flex-start;
    margin-top: 0.5rem;
    margin-right: 0.5rem;

}
