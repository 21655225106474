

.waitListBackground {
	background-image: url('./backgroundPic.png');
	background-size: cover;
	height: 100vh;
}

.waitingListContainer {
	height: 100%;
	width: 100%;
}

.waitListMain {
	display: flex;
	justify-content: center;
}
.waitListTitleSection {
	margin-top: 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 2rem;
	margin-left: 2rem;

}

.waitListTopText {
	margin-top: 3rem;
}

.waitListTitleWord {
	font-size: 3.5rem;
	font-weight: bold;
	color: #181415;
}

.waitListTitleText {
	font-size: 1.5rem;
	width: 15rem;
	text-align: center;
	color: #181415;
}

.waitListTitleTextMobile {
	font-weight: 700;
	font-size: 2rem;
}

.waitListForm {
	display: flex;
    flex-direction: column;
    margin-right: 30%;
    margin-left: 30%;
    margin-top: 5%;
}

.waitListFormMobile {
	display: flex;
    flex-direction: column;
   	margin-right: 2%;
   	margin-left: 2%;
    margin-top: 5%;
}

.waitListFormRefer {
	display: flex;
    flex-direction: column;
    margin-right: 30%;
    margin-left: 30%;
    margin-top: 2%;
}


.waitListFormReferMobile {
	display: flex;
    flex-direction: column;
    margin-right: 2%;
    margin-left: 2%;
    margin-top: 2%;
}

.waitListFormField {
    width: 100%;
    border: none;
    padding: 0.2rem;

}

.waitListFormError {
	display: flex;
	justify-content: center;
	color: #8E1600;
}

.waitListFormField:focus, .autocompleteField:focus {
	outline: none;
}

.waitingList .autocompleteField {

    width: 100%;
    border: none;
    padding: 0.2rem;
    border-radius: 0;
    margin-bottom: 1rem;

}

.waitingListButton {
	all: unset;
	background-color: #19A59B;
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	color: white;
	font-weight: bold;
}
.waitListLogoHolder {
	display: flex;
	justify-content: center;
	    margin-bottom: 2rem;
}

.waitListLogoMobileHolder {
	display: flex;
	justify-content: center;
	align-items: center;
    margin-bottom: 2rem;
}

.waitListLogo {
	height: 100%;
	width: 10%;
}

.waitListLogoMobile {
	height: 100%;
	width: 10%;
	margin-top: 5rem;
}

.waitListLogoMobile {
	width: 50%;
}

.waitListItem {
	margin-bottom: 0.5rem;
}

.waitListNamesMain {
	display: flex;

}

.waitListMainMobile {
	display: flex; 
	flex-direction: column;
	align-items: center;
}


.waitListNames {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
}

.waitListNameLeft {
	margin-right: 0.2rem;
	width: 50%;
}


.waitListNameRight {
	margin-left: 0.2rem;
	width: 50%;
}

.waitListExtraText {
	align-self: center;
}


.waitListSubTextSection {
	display: flex; 
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 2rem;
}

.waitListSubText {
	color: #19A59B;
	font-size: 2rem;
	font-weight: 700;
}

.waitListSmallSubText {
	color: #19A59B;
	font-size: 1.2rem;
	font-weight: 500;
}








