.profileContainer .createMessageForm, 
.locationContainer .createMessageForm {
	margin-top: 5rem;
}


.messageUser {
	display: flex;
	align-items: center;
}

.createMessageForm .picMedium {
	margin-right: 1rem;
}


.createMessageHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

}

.createMessageForm .locationTag {
	height: 1.5rem;
}
