.messageForm {
	margin-top: 1rem;
	width: 100%;
}

.messageForm .formField {
	width: 100%;
	height: 5rem;
}

.messageFormButtons {
	display: flex;
	justify-content: flex-end;
	margin-right: 1rem;
}

.messageForm .primaryButton {
	padding: 0.2rem 1rem;
}