.tripLocationContainer .toggleButtons {
	margin-top: 1rem;
}

/*.tripLocationHeader .secondaryButton {
	height: 1.5rem;
    width: 4rem;
   
}*/
 
.locItineraryHeader .secondaryButton {
	height: 1.5rem;
    padding: 0rem;
    text-align: center;
    vertical-align: middle;
    margin-right: 0.5rem;
}