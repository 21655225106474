.saved {
	height: 1.2rem;
}

.attractionContainer .saved {
	height: 1.5rem;
}

.saveCount {
	text-align: center;
}

.saveButtonHolder {
	display: flex; 
	flex-direction: column; 
	align-items: center;
}

.saveButtonLabel {
	font-size: 0.5rem;
	line-height: 0.5rem;
}

.attractionContainer .saveButtonLabel {
	font-size: 0.7rem;
	line-height: 0.7rem;
}

