
.profileHeader {
	display: flex;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	margin-left: 2rem;
}

.profHeaderLeft {
	width: 60%;
	display: flex;
}

.profHeaderContent {
	margin-left: 2rem;
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}


/*.profileHeader .primaryButton {
	height: 1.75rem;
	width: 6rem;
	font-size: 0.75rem;
	padding: 0;
}*/



.profPic {
	height: 10rem;
	width: 10rem;
	border-radius: 100%;
	position: relative;
}

.newProfPicButton:hover {
	font-size: 0.5rem;
	background: rgba(128,128,128, 0.6);
	margin: -2.75rem 1rem 0 1rem;
	color: white;
	position: absolute;
	height: 2rem;
	width: 6rem;
	text-align: center;
	vertical-align: middle;
	border-radius: 0 0 100% 100%;
	z-index: 2;

}


.newProfPicButton {
	font-size: 0.5rem;
	background: transparent;
	margin-top: -2.5rem;
	color: transparent;
	position: absolute;
	z-index: 2;
}


.profHeaderRight {
	width: 40%;
	margin-left: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.profHeaderNumbers {
	display: flex;
	justify-content: center;
	align-items: center;
}


.numberItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 1rem;
}

.number {
	font-weight: 700;
}

.numberType {
	font-size: 0.75rem;
}