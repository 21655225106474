.savedFilter {
	display: flex;
	justify-content: flex-end;
}

.savedHeader .formField, 
.savedHeader .autocompleteField {
	width: 18rem;
}

.savedHeader .autocomplete {
	height: 2rem;
}

