
.tripItineraryDays {
	display: flex;
	flex-flow: wrap;
}

.tripItineraryDay {
	min-height: 16rem;
	width: 14%;
	margin-right: 0.25%;
}

.tripItineraryItem {
	margin-bottom: 0.3rem;
}

.tripItinDate {
	margin-bottom: 0.5rem;
}

.tripItineraryButtons {
	display: flex;
	justify-content: space-between;
}

.tripItinButtons {
	display: flex;
	align-items: center;
	flex-basis: 100%;
}

.tripItinButtonsRight {
	justify-content: flex-end;
	margin-right: 2rem;
}

.tripItinItemButtons {
	display: flex;
}

.tripItineraryDay .textTitle {
	white-space: initial;
	word-wrap: break-word;
}

