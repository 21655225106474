.locItinerary {
	min-height: 30rem;
}
.locItineraryHeader {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
}


.itineraryTable {
	height: 46rem;
}

.locItinerary .mainBackground {
	height: 100%;
	overflow-y: scroll;
}

.locItinerary .tabButtons {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}

.tabAndArrow {
	display: flex;
	justify-content: space-between;
	width: 10rem;
}

.itineraryEditButtons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
}