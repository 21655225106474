.tripAddedLocations {
	display: flex;
	margin-left: 4rem;
	flex-wrap: wrap;
} 

.addTripLocations {
	display: flex;
	justify-content: space-between;

}

.createTripLocations .textTitle {
	margin-right: 1rem;
	margin-top: 1rem;
	text-align: center;
}

/*This is to account for autocomplete being a form*/
.createTripLocations .form {
	height: 4rem;
} 

