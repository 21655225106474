.searchHeader {
	margin-bottom: 2rem;
}

.searchHeaderMiddle {
	display: flex;
	justify-content: center;
}

.searchHeaderBottom {
	display: flex;
	justify-content: center;
}

.keyWordAndSort {
	display: flex;
	justify-content: space-around;
	align-items: center;

}

.searchHeader label{
	white-space: nowrap;
}

.searchHeader .iconMedium {
	margin-right: 0.5rem;
}

.keyWordSection {
	display: flex;
	margin-right: 1rem;
	display: flex;
	align-items: center;
	width: 100%;
}


.checkboxesAndFilters {
	margin-top: 1rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 50%;
}


.searchHeader .checkboxesSection {
	display: flex;
}

.searchHeader .checkboxSection {
	margin-right: 1rem;
}

.searchHeader .dropdownSection {
	display: flex;
}
