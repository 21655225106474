.groupTripLocations .form {
	width: 100%;
}

.groupTripLocations .mainBackground {
	padding: 0.5rem;
}

.groupTripLocsContent {
	overflow-y: scroll;
	height: 15rem;
	width: 100%;
}

.groupTripLocations .formButtons {
	margin-top: 0.5rem;
}