.tripDates {
	display: flex;
}

.tripHeaderButtons {
	display: flex;
	align-items: center;
	margin-top: 0.2rem;
}

.tripHeader .editIcon {
	margin-left: 0.5rem;
}