
.followButton {
	display: flex;
}

.followButton .dropdown {
	width: 100%;
	margin-left: 0.5rem;
}

.followButton .dropdownToggle, 
.followButton .dropdownToggle:hover, 
.followButton .dropdownToggle:focus, 
.followButton .dropdownToggle:active {
	font-size: 2rem;
}

.followView .followButton {
	font-size: 0.7rem;
}