/*small 70/30*/
.overallAttractionContainer .mapView, 
.attractionContainer .mapView, 
.locationContainer .mapView, 
.overallLocationContainer .mapView, 
.savedLocationContainer .mapView, 
.tripLocAttrOverview .mapView, 
.tripContainer .mapView, 
.searchContainer .mapView {
	height: 18rem;
	margin-bottom: 1rem;
}

.overallAttractionContainer #map, 
.attractionContainer #map, 
.locationContainer #map, 
.overallLocationContainer #map, 
.savedLocationContainer #map, 
.tripLocAttrOverview #map, 
.tripContainer #map, 
.searchContainer #map {
	height: 18rem;
	margin-bottom: 1rem;
}

.searchContainer .mapView {
	position: fixed;
	width: 25%;
}

.tripLocAttrItinerary .mapView {
	height: 20rem;
}

.tripLocAttrItinerary #map {
	height: 20rem;
}

.profileContianer .mapView, 
.savedContainer .mapView, 
.exploreContainer .mapView {
	width: 100%;
	height: 30rem;
	margin-bottom: 2rem;
}

.profileContainer #map, 
.savedContainer #map, 
.exploreContainer #map {
	width: 100%;
	height: 30rem;
}
