.commentsContainer .formField {
	height: 4rem;
	width: 100%;
}

.overallAttractionContainer .formField {
	height: 2rem;
}

.formCommentEditButtons {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}

.formCommentAddButtons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
}
