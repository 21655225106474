
.settingsContent {
	display: flex;
    justify-content: center;
    margin-top: 2rem;

}



.settingsItem {
	display: flex;
	justify-content: space-between;

}

.settingsTitle {
	width: 50%;
}

.settingsValue {
	display: flex;
	align-items: center;
}

.settingsContainer .secondaryButton {
	margin-left: 1rem;
}


.publicityLabel {
	font-weight: bold;
	margin-right: 1rem;
}

