.logoIcon {
	height: 3rem;
	margin-left: 7rem;
	margin-top: 1rem;
	color: #105B57;
}

.landingPageMobile .logoIcon {
	margin-left: 0;
}


.landingPageNav {
	display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 4rem;
    padding-top: 1rem;
    margin-right: 2rem;
}

.landingPageMobile .landingPageNav {
	justify-content: center;
	margin-right: 0;
}

.landingPageMain {
	display: flex;
}




.landingPageNavFont {
	font-family: Inter;
	font-weight: 600;
	font-size: 16px;
	margin: 1rem;
	color: #105B57;

}


.landingPageButton {
	all: unset;
	background-color: #105B57;
	padding: 0.5rem 1rem;
    border-radius: 100px;
    color: white;
    margin: 1rem;
}


.landingPageSection {
	width: 100vw;
	height: 100vh;
	display: flex;
}

.landingPageMobile .landingPageSection {
	flex-direction: column;
	height: 100%;
}

.landingPageDesktop .landingPageSectionLeft, .landingPageDesktop .landingPageSectionRight {
	height: 100vh;
	width: 50%;
}

.landingPageMobile .landingPageSectionLeft, .landingPageMobile .landingPageSectionRight {
	display: flex; 
	flex-direction: column;
	justify-content: center;

}


.landingPageMainPic {
	height: 110vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.landingPageTextSection {
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding-left: 7rem;
    padding-right: 2rem;
}

.landingPageMobile .landingPageTextSection {
	padding-left: 1rem; 
	padding-right: 1rem;
	display: flex;
	align-items: center;
}

.landingPageTitle {
	font-size: 2.5rem;
	font-family: Inter;
	font-weight: 700;
	color: #18191F;
	line-height: 3.5rem;
}

.landingPageMobile .landingPageTitle {
	font-size: 1.5rem;
	line-height: 2rem;
	text-align: center;
}

.landingPageSubtext {
	font-size: 1.1rem;
	font:'Inter';
	color: #18191F;

}

.landingPageMobile .landingPageSubtext {
	text-align: center;
}

.landingPageForm {
	margin-top: 2rem;
	width: 100%;
}


.PhoneInputCountryIconImg {
	display: none;
}


.PhoneInputCountry {
	display: flex;
}

.PhoneInput {
	display: flex;
}

.PhoneInputInput, .PhoneInputCountrySelect {
	width: 100%;
}

.landingPage .autocompleteField, .PhoneInputInput, .PhoneInputCountrySelect, .landingPageField {
	background: rgba(191, 226, 220, 0.2);
    border-radius: 100px;
    border: 0;
    height: 3rem;
    padding: 0.5rem;
    color: #105B57;
    width: 100%;
    margin: 0.2rem;

}

.landingPage .autocompleteField::placeholder, .PhoneInputInput::placeholder, .landingPageField::placeholder {
	color: #105B57;
}

.landingPage .formItem {
	height: 3rem;
	margin: 0;
}

.landingPageNames {
	display: flex;
	width: 100%;
}

.landingPageName {
	width: 100%;
}

.landingPageButtonHolder {
	display: flex;
	align-items: center;
	justify-content: center;
}

.landingPageButtonHolder .landingPageButton {
	font-size: 1.2rem;
	border-radius: 100px;
}

.landingPagePicture {
	width: 50vw;
	object-fit: contain;
}

.landingPageMobile .landingPagePicture {
	width: 100%;
}


.landingPagePictureSmall {

}

.landingPageIcon {
	height: 4rem;

}

.landingPageSectionBottom {
	background-color: #F2F9F8;
	align-items: center;
	justify-content: center;
}

.landingPageSectionBottom .landingPageTextSection {
	align-items: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
}

.landingPageMobile .landingPageSectionBottom .landingPageTextSection {
	width: 80%;
	margin-top: 2rem;
}

.landingPageSectionBottom .landingPageTitle {
	text-align: center;
}

.landingPageButtonLarge {
	padding: 1.5rem;
}