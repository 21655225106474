
.attractionsContainer .attractionTags {
	margin-top: 1rem;
}

.overallAttractionContainer .attractionTagSmall {
	margin-right: 0.5rem;
	white-space: nowrap;
}

.overallAttractionContainer .attractionTagsContent {
	margin-top: 0.5rem;
}

.overallAttractionContainer .attractionTags {
	align-items: flex-start;
}


.allTags.attractionTags {
	align-items: flex-start;
}