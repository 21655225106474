.attraction {
	min-height: 4rem;
}



.attraction.mainBackground {
	padding: 0.5rem 1rem;
}

.attractionMain {
	display: flex;
}

.attractionContent {
	width: 70%;

}

.attraction .icons {
	margin-left: auto;
}

.readMore {
	display: inline;
}

.attraction .attractionRight {
 	width: 30%;
 	margin-left: 1rem;
 	margin-top: 2rem;
 	display: flex;
 	flex-direction: column;
 	justify-content: space-between;
}


/*.attraction .textTitle {
	width: 6rem;
}*/

