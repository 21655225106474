
.allPicturesView .popupContent {
	display: flex;	
	flex-wrap: wrap;
	overflow: hidden;
	overflow-y: scroll;
	flex-direction: row;
	align-items: flex-start;
	height: unset;
}

.singlePicture {
	height: 8rem;
	width: 8rem;
	margin-right: 0.75rem;
	margin-bottom: 0.75rem;
}

.googlePic {
	height: 8rem;
	width: 8rem;
	margin-right: 0.75rem;
	margin-bottom: 0.75rem;
	overflow: hidden;
}