.followView .mainBackground {
	padding: 0.5rem;
}

.follow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.followUserInfo {
	display: flex;
	align-items: center;
}

.followView .picSmall {
	margin-right: 1rem;
}