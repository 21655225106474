
.createAttractionTags {
	width: 100%;
}


.createAttractionTags .attractionTags {
	display: flex;
	flex-direction: row;
	margin-left: 4rem;
	flex-wrap: wrap;
}

.createAttractionTagsContent {
	margin-bottom: 0.5rem;

}