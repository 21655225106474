.pictureAdd .form {
	width: 100%;
}

.chooseFiles {
	margin-bottom: 0.5rem;
}

.pictureInput {
	display: none;
}

.selectedPictures{
	width: 100%;
	height: 100%;
	display: flex;	
	flex-wrap: wrap;
	overflow: hidden;
	overflow-y: scroll;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
}


.selectedPictureHolder {
	height: 10rem; 
	width: 10rem;
}

.selectedPicture {
	padding: 0.5rem;
	height: 10rem;
	width: 10rem;
}


.selectedAttraction {
	display: flex;
	align-items: center;
}

.selectedPictureContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 0.5rem;
	margin-top: 0.5rem;
}
