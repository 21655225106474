.pictureView .popupContent {
	padding-left: 1rem;
	flex-direction: row;
}

.pictureView.popupXL {
	width: 70%;
	margin-left: 5%;
	margin-right: 5%;
}

/*.pictureContentButtons {
	display: flex;
	justify-content: flex-end;
}
*/

.first {
	opacity: 0;
}

.last {
	opacity: 0;
}

.picViewTopButtons {
	display: flex;
	margin-bottom: 0.5rem;
	align-items: center;
}

.picViewTopButtons .deleteIcon {
	margin-right: 0.5rem;
}

.pictureViewPic {
	width: 100%;
    object-fit: contain;
    max-height: 28rem;
}


.pictureView .attractionTagSmall {
	margin-left: 0rem;
}

.pictureView .labelAndTooltip {
	margin-right: 0.5rem;
}

.pictureViewLeft {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.pictureViewContent {
	display: flex;
	justify-content: space-between;
}

.pictureViewRight {
	width: 30%;
	margin-left: 0.5rem;
	height: 100%;
}


.pictureViewContent .secondaryButton {
	margin-left: 2rem;
	margin-top: 1rem;
}

.pictureAndTags {
	position: relative;
	width: 100%;
}

.arrowHolder {
	display: flex;
    align-items: center;
}

.tagButtonHolder {
	margin-left: 1.5rem;
	margin-top: 0.2rem;
}


.pictureView .tertiaryButton:disabled {
	opacity: 0
}

.pictureViewButtons {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
}

.pictureView .primaryButton {
	padding: 0 0.2rem;
}




