.attrUserInfo {
	margin-right: 2rem;
	margin-left: 0.5rem;
}

.nameAndType {
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.reviewContent {
	display: flex;
}

.overallAttractionReviews .icons {
	align-self: flex-start;
}