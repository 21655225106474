.picture {
	border-radius: 15%;
	height: 8rem;
	width: 8rem;
	object-fit: cover;
}

.pictureHolder {
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
}

.picName {
	max-width: 8rem;
	overflow: hidden;
	white-space: nowrap;
	display: flex;
	justify-content: center;
}

.viewAll {
	all: unset;
}

.viewAll:focus {
	all: unset;	
}

.viewAllHolder {
	display: flex;

}