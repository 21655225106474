
.basicInfoItem {
	margin-bottom: 0.5rem;
	display: flex;
	align-items: baseline;
}

.hoursToggle {
	all: unset;
	font-weight: bold;
}


.attractionBasicInfo .secondaryButtonHolder {
	display: flex;
	justify-content: center;
}

.dollarSigns {
	display: flex;
}

.opaqueSign {
	opacity: 0.2;
}