
.iconSmall {
	height: 1.5rem;
	width: 1.5rem;
	font-size: 24px;
	margin-right: 0.1rem;
	margin-left: 0.1rem;
}

.iconMedium {
	height: 2rem;
	width: 2rem;
	font-size: 32px;
	margin-right: 0.1rem;
	margin-left: 0.1rem;
}

.iconLarge {
	height: 2.5rem;
	width: 2.5rem;
	font-size: 40px;
	margin-right: 0.1rem;
	margin-left: 0.1rem;
}

.iconXL {
	height: 2.5rem;
	width: 2.5rem;
	margin-right: 0.2rem;
	margin-left: 0.2rem;
}


.uploadPicturesIcon {
	height: 2rem;
	width: 2rem;
}

.addAndSaveButtons {
	display: flex;
}

.editAndDeleteButtons {
	display: flex;
}

.iconMedium.plusIcon, 
.iconMedium.editIcon {
	height: 1.3rem; 
	width: 1.3rem;
}


.tooltipIcon {
	border: 1px solid black;
	height: 1rem;
	width: 1rem;
	border-radius: 100%;
	display: flex; 
	justify-content: center;
	align-self: center;
	align-items: center;
}

.removeIcon {
	border-radius: 100%;
	background-color: red;
	color: white;
	font-weight: bold;
	height: 1rem;
	width: 1rem;
	text-align: center;
	margin-left: 0.5rem;
	align-self: center;
}

.backIcon {
	margin-right: 0.5rem;
}

.starSmall {
	height: 0.7rem;
	margin-right: 0.1rem;
}

.star{
	height: 1rem;
	margin-right: 0.1rem;
	/*color need to be done on the image level*/
}

.starLarge {
	height: 1.5rem;
	margin-right: 0.2rem;
}

.headerStar {
	height: 1.5rem;
	margin-right: 0.5rem;
}

.messageIcon { 
	height: 1.5em;
}


.notificationIcon {
	height: 2rem;
}

/*.notificationDot {
	height: 0.5rem;
	width: 0.5rem;
	margin-bottom: -1rem;
	margin-left: -0.25rem;
}
*/
.arrowDown {
	height: 1rem;
	width: 1rem;
}



