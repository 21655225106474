.addSelection {
	display: flex; 
	flex-direction: column;
	height: 100vh;
}

.addSelectionHolder {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 1rem;
}

.addButton {
	margin-top: 1rem;
}

