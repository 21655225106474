.attractionHeader {
	margin-top: -0.5rem;
}

.attractionHeaderContent {
	
	display: flex;
	flex-direction: column;
}

.attractionHeaderTop {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.nameAndStars {
	display: flex;
	margin-bottom: 0.5rem;
}

.attractionHeader .tertiaryHeader {
	margin-right: 1rem;
}