.tripTransportationsHeader {
	display: flex;
	justify-content: space-between;
}

.transportationCategoryTitles {
	display: flex;
	justify-content: space-around;
}

.transportation {
	display: flex;
	justify-content: space-between;
}


.tripTransportationsButtons {
	display: flex;
	align-items: center;
	margin-right: 0.2rem;
}

.transportationButtons {
	display: flex;
	align-items: center;
}

.transportationItem, 
.transportationCategoryTitle {
	flex-basis: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.transportationUserInfo {
	display: flex;
	align-items: center;
	flex-direction: row;
}