
.allPicturesView .popupContent {
	display: flex;	
	flex-wrap: wrap;
	overflow: hidden;
	overflow-y: scroll;
	flex-direction: row;
	align-items: flex-start;
	height: unset;
}

.singlePicture {
	height: 8rem;
	width: 8rem;
	margin-right: 0.75rem;
	margin-bottom: 0.75rem;
}

.googlePic {
	height: 8rem;
	width: 8rem;
	margin-right: 0.75rem;
	margin-bottom: 0.75rem;
	overflow: hidden;
}
.picture {
	border-radius: 15%;
	height: 8rem;
	width: 8rem;
	-o-object-fit: cover;
	   object-fit: cover;
}

.pictureHolder {
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
}

.picName {
	max-width: 8rem;
	overflow: hidden;
	white-space: nowrap;
	display: flex;
	justify-content: center;
}

.viewAll {
	all: unset;
}

.viewAll:focus {
	all: unset;	
}

.viewAllHolder {
	display: flex;

}

.basicInfoItem {
	margin-bottom: 0.5rem;
	display: flex;
	align-items: baseline;
}

.hoursToggle {
	all: unset;
	font-weight: bold;
}


.attractionBasicInfo .secondaryButtonHolder {
	display: flex;
	justify-content: center;
}

.dollarSigns {
	display: flex;
}

.opaqueSign {
	opacity: 0.2;
}

.createAttractionTags {
	width: 100%;
}


.createAttractionTags .attractionTags {
	display: flex;
	flex-direction: row;
	margin-left: 4rem;
	flex-wrap: wrap;
}

.createAttractionTagsContent {
	margin-bottom: 0.5rem;

}
/*.signupContainer .formComponent, 
.loginContainer .formComponent {
	margin: 0 15%;
	display: flex;
	background-color: lightgrey;
}

.tripLocationsAndMap {
	
}

.signupContainer .formPictureHolder, 
.loginContainer .formPictureHolder{
	width: 50%;
	overflow: hidden;
}


.autoForm {
	width: 100%;
}

.signupContainer .formTitle, 
.loginContainer .formTitle {
	margin-top: 6rem;
	margin-bottom: 2rem;

}

.currentCity .formTitle {
	text-align: center;
}

.createTripContainer .formTitle {
	font-weight: bold;
	font-size: 1rem;
}

.signupContainer .formContent,
.loginContainer .formContent {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	
}


.locationContainer .formComponent,
.attractionContainer .formComponent,
.profileContainer .formComponent, 
.itineraryContainer .formComponent  {
	width: 46%;
	display: flex;
	flex-direction: column;
	z-index: 3;
	position: absolute;
	background-color: white;
	border: 2px solid black;
	border-radius: 10px;
	margin-left: 7%;
	height: 22rem;
	padding: 1rem;
	margin-top: 6rem;
}


.profPicChange .formComponent {
	height: 30rem;
}

.addAttractionContainer .formComponent {
	margin-top: -16rem;
}

.attractionDetails .formComponent {
	margin-top: 0;
	height: 32rem;
}



.locationContainer .formTitle,
.profileContainer .formTitle {
	align-self: center;
	margin-bottom: 1rem;

}



.formContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}


.currentCity .formContent {
	width: 100%;
	padding: 0 18%;
}

.locationContainer .form {
	height: 14rem;
}

.attractionDetails .form {
	margin-top: 0;
	height: 28rem;
}

.profPicChange .form, 
..singlePicAdd .form {
	height: 2rem;
}

.profPicChange .pictureUpload, 
..singlePicAdd .pictureUpload {
	height: 2rem;
	width: 10rem;
	padding: 0;
	margin-bottom: 0;
}

.profPicChange .pictureUpload:hover, 
..singlePicAdd .pictureUpload:hover {
	height: 2rem;
	width: 10rem;
	padding: 0;
	margin-bottom: 0;
}


.cropper {
	margin-top: 0.5rem;
	height: 18rem;
	width: 18rem;
}

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.formItem {
	display: flex;
	justify-content: flex-end;
	margin-top: 0.5rem;
}

.formLabel {
	margin-right: 1rem;
	font-weight: 600;
	align-self: center;
}

.formField {
	all: unset;
	background-color: white;
	border: 1px solid black;
	border-radius: 10px;
	padding: 0 0.5rem;
}

.currentCity .formField, 
.addLocationContainer .formField, 
.addAttractionContainer .formField {
	height: 2rem;
	width: 12rem;
}




.profPicChange .cancel{ 
	margin-top: 18rem;
}

..singlePicAdd .cancel {
	margin-top: 10rem;
}

.formCancel {
	margin-left: 2rem;
}

.formCancel:hover {
	margin-left: 2rem;
	background-color: grey;
}

.formSubmit {
	margin-right: 2rem;
}

.formSubmit:hover {
	margin-right: 2rem;
	background-color: grey;
}
*/
/*.formSubmit {
	all: unset;
	text-align: center;
	border: 2px solid black;
	padding: 0.5rem;
	width: 8rem;
	border-radius: 10px;
	background-color: lightslategrey;

}

.formSubmit:hover {
	all: unset;
	text-align: center;
	border: 2px solid black;
	padding: 0.5rem;
	width: 8rem;
	border-radius: 10px;
	background-color: grey;

}*/

/*.formSubmitHolder {
	margin-top: 1rem;
	display: flex;
	justify-content: space-around;
}

.formSubmitHolder:focus {
	outline: none;
}

.formButtons {
	width: 100%;
	margin-top: auto;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}


.signupBasic .formButtons, 
.profileContainer .formButtons, 
.locationContainer .formButtons {
	justify-content: space-around;
}

.formComponent {
	height: 100%;
}

.formContent {
	height: 100%;
}

.form {
	height: 90%;
}*/



/*.editBasicInfo .formButtons {
	margin-top: 4rem;
}*/

/*.profPicChange .formButtons, 
..singlePicAdd .formButtons {
	margin-top: 0.5rem;
}*/
/*

.addLocationContainer .formButtons, 
.addAttractionContainer .formButtons, 
.currentCity .formButtons {
	justify-content: space-around;
	margin-top: 11rem;

}


.attractionDetails .formButtons {
	margin-bottom: 1rem;
	justify-content: space-between;
}*/


/*.locationDetails .formButtons {
	margin-top: 5rem;
}*/

/*.formButtons:focus {
	outline: none;
}

.detailsStar {
	height: 1rem;
	width: 1rem;
}

.locationDetails .formFieldLarge, 
.attractionDetails .formFieldLarge {
	height: 8rem;
	width: 12rem;
}


.formError {
	font-size: 0.75rem;
	display: flex;
	justify-content: center;
	color: red;
}

.autocompleteField {
	width: 28rem;
}

#searchAutocomplete {
	width: 22rem;
}*/

.formStars {
	display: flex;
	margin-right: 3rem;
}
.attractionHeader {
	margin-top: -0.5rem;
}

.attractionHeaderContent {
	
	display: flex;
	flex-direction: column;
}

.attractionHeaderTop {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.nameAndStars {
	display: flex;
	margin-bottom: 0.5rem;
}

.attractionHeader .tertiaryHeader {
	margin-right: 1rem;
}
.addToTripIcon {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 0.3rem;
}

.addToTripLabel {
	font-size: 0.5rem;
	text-align: center;
	width: 3rem;
}

.attractionContainer .addToTripLabel {
	font-size: 0.7rem;
	width: 3.5rem;
}


.attractionsContainer .attractionTags {
	margin-top: 1rem;
}

.overallAttractionContainer .attractionTagSmall {
	margin-right: 0.5rem;
	white-space: nowrap;
}

.overallAttractionContainer .attractionTagsContent {
	margin-top: 0.5rem;
}

.overallAttractionContainer .attractionTags {
	align-items: flex-start;
}


.allTags.attractionTags {
	align-items: flex-start;
}
.saved {
	height: 1.2rem;
}

.attractionContainer .saved {
	height: 1.5rem;
}

.saveCount {
	text-align: center;
}

.saveButtonHolder {
	display: flex; 
	flex-direction: column; 
	align-items: center;
}

.saveButtonLabel {
	font-size: 0.5rem;
	line-height: 0.5rem;
}

.attractionContainer .saveButtonLabel {
	font-size: 0.7rem;
	line-height: 0.7rem;
}


.attractionContainer .commentsContainer  {
	margin-top: 2rem;
}

.deletion .tertiaryHeader {
	text-align: center;
}

.deletion {
	z-index: 5;
}
.commentIcons {
	align-self: center;
}

.commentHeader, 
.commentsHeaderTop, 
.commentsHeaderBottom {
	display: flex;
	align-items: center;
}

.commentsContainer .textLink {
	margin-right: 0.5rem;
}


.edited {
	font-size: 0.7rem;
	color: grey;
	align-self: center;
}

.pictureView .comments {
	margin-top: 1rem;
}


.pictureView .comments {
	height: 25rem;
	overflow-y: scroll;
}

.loginMessage {
	margin-bottom: 0.5rem;
}
.commentsContainer .formField {
	height: 4rem;
	width: 100%;
}

.overallAttractionContainer .formField {
	height: 2rem;
}

.formCommentEditButtons {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}

.formCommentAddButtons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
}

/*small 70/30*/
.overallAttractionContainer .mapView, 
.attractionContainer .mapView, 
.locationContainer .mapView, 
.overallLocationContainer .mapView, 
.savedLocationContainer .mapView, 
.tripLocAttrOverview .mapView, 
.tripContainer .mapView, 
.searchContainer .mapView {
	height: 18rem;
	margin-bottom: 1rem;
}

.overallAttractionContainer #map, 
.attractionContainer #map, 
.locationContainer #map, 
.overallLocationContainer #map, 
.savedLocationContainer #map, 
.tripLocAttrOverview #map, 
.tripContainer #map, 
.searchContainer #map {
	height: 18rem;
	margin-bottom: 1rem;
}

.searchContainer .mapView {
	position: fixed;
	width: 25%;
}

.tripLocAttrItinerary .mapView {
	height: 20rem;
}

.tripLocAttrItinerary #map {
	height: 20rem;
}

.profileContianer .mapView, 
.savedContainer .mapView, 
.exploreContainer .mapView {
	width: 100%;
	height: 30rem;
	margin-bottom: 2rem;
}

.profileContainer #map, 
.savedContainer #map, 
.exploreContainer #map {
	width: 100%;
	height: 30rem;
}

.pictures .mainBackground.picturesContent {
	border-radius: 20px;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
}

.picturesRow {
	display: flex;
	overflow-x: auto;
}

.attractionPictures {
	margin-bottom: 1rem;
}

.pictures .iconLarge {
	margin-left: 0.5rem;
	margin-bottom: 0.5rem;
}
.pictureView .popupContent {
	padding-left: 1rem;
	flex-direction: row;
}

.pictureView.popupXL {
	width: 70%;
	margin-left: 5%;
	margin-right: 5%;
}

/*.pictureContentButtons {
	display: flex;
	justify-content: flex-end;
}
*/

.first {
	opacity: 0;
}

.last {
	opacity: 0;
}

.picViewTopButtons {
	display: flex;
	margin-bottom: 0.5rem;
	align-items: center;
}

.picViewTopButtons .deleteIcon {
	margin-right: 0.5rem;
}

.pictureViewPic {
	width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    max-height: 28rem;
}


.pictureView .attractionTagSmall {
	margin-left: 0rem;
}

.pictureView .labelAndTooltip {
	margin-right: 0.5rem;
}

.pictureViewLeft {
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.pictureViewContent {
	display: flex;
	justify-content: space-between;
}

.pictureViewRight {
	width: 30%;
	margin-left: 0.5rem;
	height: 100%;
}


.pictureViewContent .secondaryButton {
	margin-left: 2rem;
	margin-top: 1rem;
}

.pictureAndTags {
	position: relative;
	width: 100%;
}

.arrowHolder {
	display: flex;
    align-items: center;
}

.tagButtonHolder {
	margin-left: 1.5rem;
	margin-top: 0.2rem;
}


.pictureView .tertiaryButton:disabled {
	opacity: 0
}

.pictureViewButtons {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
}

.pictureView .primaryButton {
	padding: 0 0.2rem;
}





.pictureAdd .form {
	width: 100%;
}

.chooseFiles {
	margin-bottom: 0.5rem;
}

.pictureInput {
	display: none;
}

.selectedPictures{
	width: 100%;
	height: 100%;
	display: flex;	
	flex-wrap: wrap;
	overflow: hidden;
	overflow-y: scroll;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
}


.selectedPictureHolder {
	height: 10rem; 
	width: 10rem;
}

.selectedPicture {
	padding: 0.5rem;
	height: 10rem;
	width: 10rem;
}


.selectedAttraction {
	display: flex;
	align-items: center;
}

.selectedPictureContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 0.5rem;
	margin-top: 0.5rem;
}

.mainBackground.visitorsContent {
	padding: 0.5rem;
	overflow-y: scroll;
	height: 7rem;
	margin-bottom: 0.25rem;
}

.attractionsContainer .popup .attractionVisitor {
	width: 100%;
}

/*.attractionVisitor {
	width: 60%;
	overflow: hidden;
	white-space: nowrap;
}
*/
.attractionsContainer .attractionVisitor {
	margin-right: 0.2rem;
}


.visitors .picSmall {
	margin-right: 0.5rem;
}

.visitor {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	overflow: hidden;
}

.attractionsContainer .popup .visitorName {
	font-size: 1.5rem;
}

.attractionsContainer .popup .starSmall {
	height: 1.5rem; 
	width: 1.5rem;
}

.attractionsContainer .popup .visitorStars {
	width: 100%;
	margin-left: 2rem;
}

.attractionsContainer .popup .attractionVisitor {
	overflow: visible;
	overflow: initial;
}


.attractionContainer .visitor {
	justify-content: space-between;
}




.autocompleteField {
	margin-bottom: 12rem;
}

.tripLocAttractionsContainer .autocomplete .dropdownSection {
	margin-top: -12rem;
}

.basicInfo .autocomplete .form {
	height: 4rem;

}

.addContainer .dropdownSelect {
	margin-bottom: 3rem;
}

.addSelection {
	display: flex; 
	flex-direction: column;
	height: 100vh;
}

.addSelectionHolder {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 1rem;
}

.addButton {
	margin-top: 1rem;
}


.exploreLocations {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.exploreLocation {
	margin: 1.5rem;
	border-radius: 20px;
	height: 20rem;
	width: 20rem;
	margin-bottom: 2rem;
}

.exploreLink {
	display: flex;
	justify-content: center;
}

.explorePicture {
	height: 20rem;
	width: 20rem;
	-o-object-fit: cover;
	   object-fit: cover;
	border-radius: 0.8rem;
}

/*.exploreContent {
	display: flex;
	position: absolute;
	margin-top: -2.5rem;
	height: 2.5rem;
	background-color: lightgrey;
	justify-content: space-between;
	width: 20rem;
	opacity: 0.8;
	border-radius: 0 0 20px 20px;
	align-items: center;
	padding: 0 0.5rem;
}*/

.exploreContent { 
 	width: 110%;
    margin-top: -1rem;
    padding-top: 1.5rem;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    background-color: #FEDF49;
    justify-content: space-between;
    margin-left: -5%;
    margin-right: 0.5rem;
    border-radius: 0.8rem;
    align-items: center;
}

.exploreUserName {
	font-size: 0.6rem;
	white-space: nowrap;
}

.exploreUserName:hover {
	font-size: 0.6rem;
	white-space: nowrap;
	color: white;
}

.exploreLocationName {
	font-size: 0.8rem;
	font-weight: bold;

}

.exploreLocationName:hover {
	font-size: 0.8rem;
	font-weight: bold;
	color: white;

}

.iconSmall {
	height: 1.5rem;
	width: 1.5rem;
	font-size: 24px;
	margin-right: 0.1rem;
	margin-left: 0.1rem;
}

.iconMedium {
	height: 2rem;
	width: 2rem;
	font-size: 32px;
	margin-right: 0.1rem;
	margin-left: 0.1rem;
}

.iconLarge {
	height: 2.5rem;
	width: 2.5rem;
	font-size: 40px;
	margin-right: 0.1rem;
	margin-left: 0.1rem;
}

.iconXL {
	height: 2.5rem;
	width: 2.5rem;
	margin-right: 0.2rem;
	margin-left: 0.2rem;
}


.uploadPicturesIcon {
	height: 2rem;
	width: 2rem;
}

.addAndSaveButtons {
	display: flex;
}

.editAndDeleteButtons {
	display: flex;
}

.iconMedium.plusIcon, 
.iconMedium.editIcon {
	height: 1.3rem; 
	width: 1.3rem;
}


.tooltipIcon {
	border: 1px solid black;
	height: 1rem;
	width: 1rem;
	border-radius: 100%;
	display: flex; 
	justify-content: center;
	align-self: center;
	align-items: center;
}

.removeIcon {
	border-radius: 100%;
	background-color: red;
	color: white;
	font-weight: bold;
	height: 1rem;
	width: 1rem;
	text-align: center;
	margin-left: 0.5rem;
	align-self: center;
}

.backIcon {
	margin-right: 0.5rem;
}

.starSmall {
	height: 0.7rem;
	margin-right: 0.1rem;
}

.star{
	height: 1rem;
	margin-right: 0.1rem;
	/*color need to be done on the image level*/
}

.starLarge {
	height: 1.5rem;
	margin-right: 0.2rem;
}

.headerStar {
	height: 1.5rem;
	margin-right: 0.5rem;
}

.messageIcon { 
	height: 1.5em;
}


.notificationIcon {
	height: 2rem;
}

/*.notificationDot {
	height: 0.5rem;
	width: 0.5rem;
	margin-bottom: -1rem;
	margin-left: -0.25rem;
}
*/
.arrowDown {
	height: 1rem;
	width: 1rem;
}




* {
	color: #707070;
}


/*Main Container Layout*/
.attractionContainer, 
.locationContainer, 
.overallLocationContainer, 
.overallAttractionContainer, 
.savedContainer, 
.tripsContainer, 
.searchContainer, 
.savedLocationContainer, 
.notificationsContainer.fullScreen, 
.tripLocationContainer, 
.tripContainer, 
.tripItineraryContainer, 
.allMessagesContainer, 
.settingsContainer, 
.profileContainer, 
.exploreContainer, 
.addContainer {
	margin-right: 10%;
	margin-left: 10%;
	margin-bottom: 3%;
	overflow: hidden;
	padding-top: 1.2rem;
}

/*Dropdown overlay*/
.navBarContainer .notificationsContainer, 
.navBarContainer .allMessagesContainer {
	z-index: 3;
	background-color: white;
	position: absolute;
	margin-left: 55%;
	display: flex;
	flex-direction: column;
	box-shadow: 5px 5px 10px lightgrey;
	width: 30rem;
	max-height: 50rem;
	margin-top: 0.2rem;
	padding: 0.5rem;
	overflow-y: scroll;
}

.router {
	background-color: rgb(192,192,192, 0.1)
}

.allMessagesRight {
	width: 70%;
	margin-left: 1rem;
}

.attractionContainerContent, 
.messagesContent, 
.attractionsContent, 
.overallAttractionContent, 
.overallLocContent, 
.savedLocationContent, 
.searchContent, 
.tripContent, 
.tripLocationsContent, 
.tripLocAttrOverview, 
.tripLocItinerary {
	display: flex;
}

.attractionLeft, 
.attractionsLeft, 
.overallAttractionLeft, 
.overallLocLeft, 
.savedLocationLeft, 
.searchLeft, 
.tripLeft, 
.tripLocationLeft, 
.tripLocAttrOverview .tripLocAttrLeft, 
.tripLocationsLeft {
	width: 65%;
	margin-right: 1rem;
}

.attractionRight,  
.attractionsRight, 
.overallAttractionRight, 
.overallLocRight, 
.savedLocationRight, 
.searchRight, 
.tripRight, 
.tripLocAttrRight, 
.tripLocationsRight {
	width: 35%;
}


.tripLocItineraryLeft {
	width: 50%;
	margin-right: 1rem;
}

.tripLocItineraryRight {
	width: 50%;
}



/*initial screen layout*/
.initialScreen {
	display: flex;
}


.initialScreenLeft {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	margin-top: 0rem;
	width: 50%;
	flex-direction: column;
	align-items: center;
	display: flex;
}

.mainHeader.initialHeader {
	font-size: 3rem;
}

.secondaryHeader.initialHeader, 
.currentCity .secondaryHeader, 
.signupContainer .singlePicAdd .secondaryHeader {
	margin-top: 7rem;
}

.initialContent {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.initialScreenRight {
	width: 50%;
}

.initialPicture {
	height: 100vh;
	width: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
}

.initialItem {
	margin: 1rem;
}

.initialItems {
	width: 70%;
	display: flex; 
	flex-direction: column;
	justify-content: center;
	align-items: center; 
}




/*Background*/
.mainBackground {
	padding: 0.5rem 1rem;
	width: 100%;
	font-size: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(254, 223, 73, 0.4);
	border-radius: 10px;
} 



.secondaryBackground {
	background-color: rgba(51, 204, 204, 0.5); 
	border-radius: 30px;
    display: inline-block;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: auto;
}


.mainBackgroundColor {
	background-color: rgba(254, 223, 73, 0.4);
}

.secondaryBackgroundColor {
	background-color: rgba(51, 204, 204, 0.5);
} 



/*Buttons*/
.primaryButton {
/*	height: 2.2rem;*/
	color: white;
	background: #19A59B;
	border-radius: 2rem;
	border: none;
	box-shadow: 5px 5px 10px lightgrey;
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.primaryButton:hover {
	background-color: grey;
}

.primaryButton:focus {
	outline: none;
}

.secondaryButton {
/*	height: 2rem;*/
	color: #19A59B;
	background: white;
	border-radius: 2rem;
	border: none;
	box-shadow: 5px 5px 10px darkgrey;
	padding: 0.5rem; 
	display: flex;
	justify-content: center;
	align-items: center;
}

.secondaryButton:hover {
	background-color: lightgrey;
}

.secondaryButton:focus {
	outline: none;
}

.secondaryButton:disabled, 
.primaryButton:disabled {
	opacity: 0.5
}

.tertiaryButton {
	background-color: grey;
	color: white;
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;

}

.groupedButton {
	margin-right: 1rem;
}






.toggleButtons {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 1rem;
	justify-content: space-around;
	margin-bottom: 1rem;
}


.toggleLeft {
	box-shadow: 0px 5px 10px grey;
}

.toggleRight {
	box-shadow: 5px 5px 10px grey;
}

.toggleButton {
	padding: 0.25rem;
    width: 40%;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
	color: #33CCCC;
	cursor: pointer;
	padding: 0.5rem;
	height: 2rem;
	border-radius: 1rem;
	align-items: center; 
	background-color: #F7F7F7;

}

.toggleSelected {
	background-color: #33CCCC;
    color: white;
}

.dropdownSelect {
	background-color: white;
	width: 100%;

}

.dropdownSection {
	width: 100%;
}

.formToggle {
	display: flex;
	align-items: center;
}

.formToggleSwitch {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.formToggleText {
	white-space: nowrap;
}

.tabButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
}

.tabButton {
	background-color: white;
	color: #33CCCC;
    width: 8rem;
    display: flex;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
    height: 1.7rem;
    box-shadow: -3px -3px 8px grey;
    cursor: pointer;


}

.paginationButtons {
	display: flex;
	justify-content: center;
}

.paginationButton {
	background-color: white;
	box-shadow: -3px -3px 8px grey;
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    cursor: pointer;
}

.paginationButtonLink, 
.paginationButtonLink:hover {
	all: unset;
}

.tabSelected, 
.paginationSelected {
	background-color: #33CCCC;
	color: white;
}

.voteSelected {
	background-color: #19A59B;
	color: white;
}

.voteNotSelected {
	background-color: white;
	box-shadow: -3px -3px 8px grey;


}

.checkboxTouching {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%; 
}

.checkboxLarge {
	transform: scale(1.5);
	margin: 0.5rem;
}

.checkboxLabel {
	margin-right: 0.5rem; 
}

.checkboxSection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}


.dropdown {
	width: 15%;
	display: flex;
	justify-content: flex-end;
	margin-right: 0.5rem;
}



.dropdownToggle {
	all: unset;
	font-size: 3rem;
	margin-top: -2rem;
}

.dropdownToggle:hover {
	all:unset;
	font-size: 3rem;
	margin-top: -2rem;
}


.dropdownToggle:active {
	all:unset;
	font-size: 3rem;
	margin-top: -2rem;
	background-color: white;
	border: none;
	color: black;
}

.dropdownToggle:not(:disabled):not(.disabled):active {
	all:unset;
	font-size: 3rem;
	margin-top: -2rem;
	background-color: white;
	border: none;
	color: black;
}

.dropdownToggle:not(:disabled):not(.disabled):active:focus {
	box-shadow: none;
}

.dropdownToggle:focus {
	all:unset;
	font-size: 3rem;
	margin-top: -2rem;
}


/*Headers*/
.headerAndBack {
	display: flex;
}

.mainHeader {
	font-size: 2rem;
	font-weight: 700;
	padding-right: 0.7rem;
	margin-bottom: 0.2rem;
}

.headerUnderlineHolder {
	display: flex;
}

/*.fullHeaderHolder {
	margin-right: auto;
}
*/

.headerUnderline {
	height: 1rem; 
	margin-top: -1rem; 
	margin-left: 0.5rem; 
	background-color: #F9993A; 
	border-radius: 0.4rem; 
}

.subHeaderUnderline {
	height: 0.7rem; 
	margin-top: -0.75rem; 
	margin-left: 0.5rem; 
	background-color: #F9993A; 
	border-radius: 0.4rem; 
}

.categoryHeaderUnderline {
	height: 0.8rem; 
	margin-top: -0.8rem; 
	margin-left: 0.4rem; 
	background-color: #FEDF49; 
	border-radius: 0.4rem; 
}

.mainHeaderUnderline {
	height: 1rem; 
	margin-top: -1.35rem; 
	margin-left: 0.5rem; 
	background-color: #FEDF49; 
	border-radius: 0.4rem; 
	margin-bottom: 1rem;
}

.initialHeaderUnderline {
	background-color: #FEDF49; 
	margin-top: -1.9rem; 
	height: 1.4rem;
	margin-left: 0.7rem;
}

.headerHolder {
	display: inline-block;

}

.partialHeaderHolder {
	margin-right: auto;
}


.initialHeaderHolder {
	margin-bottom: 1rem;
}



.secondaryHeader, 
.secondarHeader:hover {
	font-size: 1.5rem;
	line-height: 2.3rem;
	font-weight: 500;
	margin-bottom: 0.2rem;
}

/*.secondaryHeaderLink, 
.secondaryHeaderLink:hover {
	color: #009656;
	font-size: 1.9rem;
	line-height: 2rem;
	font-weight: 500;
	margin-bottom: 0.1rem;
}*/

/*.secondaryHeaderLink:hover {
	text-decoration: underline;
}*/

.tertiaryHeader {
	font-size: 1.2rem;
}

.subHeader {
	font-size: 1.3rem;
    font-weight: 700;
    margin-left: 0.2rem;
    padding-right: 0.5rem;
}

.secondarySubHeader {
	font-size: 1.1rem;
	font-weight: 500;
}

.tertiarySubHeader {
	font-size: 1rem;
}

.categoryHeader {
	/*color: #476B4D;*/
	font-weight: 500;
	font-size: 1.4rem;
	margin-left: 0.2rem;
	padding-right: 0.4rem;
}



/*Text*/


.secondTextColor {
	/*color: #476B4D;*/
}

.categoryTitle {
	/*color: #476B4D;*/
	font-weight: 600;
	margin-right: 0.4rem;
}

.textTitle {
	font-weight: bold;
	margin-right: 0.2rem;
	white-space: nowrap;
	display: flex;
}

.textTitleForm {
	color: #19A59B;
}

.inlineLink {
	font-weight: bold;
	margin-right: 0.2rem;
	margin-left: 0.2rem;
}

.fontLarge, 
.fontLarge:hover {
	font-size: 1.2rem;
}

.fontXL, 
.fontXL:hover {
	font-size: 1.5rem;
}

.fontSmall, 
.fontSmall:hover {
	font-size: 0.8rem;
}

.fontXS, 
.fontXS:hover {
	font-size: 0.6rem;
}

.buttonSmall {
	font-size: 0.8rem;
    text-align: center;
    padding: 0.2rem;
}

.readMoreText {
	all: unset;
}

.userInputText {
	white-space: pre-wrap;
}

.textLink, 
.textLink:hover, 
.readMoreText, 
.readMoreText:hover {
	color: #19A59B;
	cursor: pointer;
}

.textLink:hover, 
.readMoreText:hover {
	text-decoration: underline;
	cursor: pointer;
}

.createdAt {
	font-size: 0.7rem;
	color: grey;
	align-self: center;
	margin-right: 0.5rem;
}

.required {
	color: #476B4D;
	margin-left: 0.2rem;
}


/*Pop up*/
.popup {
	background-color: white;
	border-radius: 10px;
	box-shadow: 5px 5px 10px lightgrey;
	padding: 1rem;
	z-index: 4;
	position: absolute;
	margin-top: 2%;
	pointer-events: all;
	width: 50%;
	height: 70%;
	margin-left: 15%;
	margin-right: 15%;
}

.createAccountCallout.popup, 
.uploadCallout.popup {
	margin-left: 30%;
}



.popupScroll {
	overflow-y: scroll;
}

/*.popupSmall {
	width: 40%;
	height: 20rem;
	margin-left: 20%;
	margin-right: 20%;

}

.popupMedium {
	width: 50%;
	height: 25rem;
	margin-left: 15%;
	margin-right: 15%;
}

.popupLarge {
	width: 50%;
	height: 34rem;
	margin-left: 15%;
	margin-right: 15%;
}

.popupXL {
	width: 50%;
	height: 38rem;
	margin-left: 15%;
	margin-right: 15%;
}*/

.popupContent {
	margin-top: 1rem;
	width: 100%;
	height: 90%;
	display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
}

/*/*Popup Layout*/

/*popup renders directly from container*/
.locationContainer .pictureAdd, 
.profileContainer .locationTipsForm, 
.attractionContainer .attractionDetails, 
.attractionContainer .pictureAdd, 
.locationListView, 
.locationDetails, 
.followView,
.profileContainer .singlePicAdd, 
.addLocation, 
.uploadProfPic, 
.tripsContainer .tripForm {
	margin-top: 1.5rem;
}

/* renders relative to parent */
.allPicturesView, 
.pictureView,
.addAttraction {
	position: absolute;
	top: 5.5rem;
}


/*popup will show relative to screen because it doesnt always render from same place*/
.addToTrip, 
.deletion, 
.datesForm,
.locationContainer .locationTipsForm, 
.notificationsContainer .pictureView, 
.tripContainer .createTripUsers, 
.tripTransportationForm, 
.itineraryItemForm, 
.ungroupLocations, 
.locationContainer .attractionDetails, 
.locationContainer .addAttraction {
	position: fixed;
	top: 5.5rem;
	left: 10%;
}

.tripTransportationForm, 
.itineraryItemForm {
	top: 4rem;
}


/*always rendered from same spot but rendered within route container*/
.groupTripLocations, 
.addTripLocation {
	margin-top: -3rem;
}

.addTripAttraction {
	margin-top: -5rem;
}



.attractionContainer .googlePictures.allPicturesView {
	margin-top: 15rem;
}

/*center non form popups*/
.addLocationContainer .popup, 
.addAttractionContainer .popup, 
.tripLocationsContainer .popup, 
.tripLocAttractionsContainer .popup {
	justify-content: center;
}

/*Popup in view*/
.disabledBackground { 
	pointer-events: none;
 }

/*Images*/
.picXS {
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 100%;
}

.picSmall{
	height: 2rem;
	width: 2rem;
	border-radius: 100%;
}

.picMedium {
	height: 4rem;
	width: 4rem;
	border-radius: 100%;
}

.picLarge {
	border-radius: 100%;
	height: 10rem;
	width: 10rem;
}

.examplePic {
	width: 50%; 
}

.examplePicHolder {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.squarePic {
	border-radius: 10px; 
	-o-object-fit: cover; 
	   object-fit: cover;
}

.boxPic {
	width: 80%;
	-o-object-fit: contain;
	   object-fit: contain;
}
		


/*Icons*/
.activeIcon {
	color: #19A59B;
	cursor: pointer;
}

.inactiveIcon {
	color: #19A59B;
	cursor: pointer;
	opacity: 0.2;
}

.nonClickableIcon {
	color: #F9993A;
	opacity: 0.6;
} 

.nonClickableInactiveIcon {
	color: #F9993A; 
	opacity: 0.2;
}

.instaIcon {
	height: 1.8rem;
	width: 1.8rem;
}



.notificationDot {
	height: 0.5rem;
	width: 0.5rem;
	background-color: red;
	border-radius: 50%;
	margin-bottom: -1rem;
	margin-left: -0.25rem;
}

.followerDot {
	margin-bottom: 0;
	margin-top: 0;
}

.progressDot {
	height: 2rem; 
	width: 2rem; 
	border-radius: 100%; 
	border: 1px solid #19A59B; 
	margin: 0.3rem;
} 

.progressDotFilled { 
	background-color: #19A59B; 
} 

.progressDots {
	margin-top: 2rem; 
	margin-bottom: 1rem;
	display: flex; 
	flex-direction: row; 
	justify-content: center; 
	align-items: center;
}


/*Form*/

.formHeader {
	margin-bottom: 1rem;
}

.formField {
	border: 1px solid lightgrey;
	border-radius: 1rem;
	width: 100%;
	padding: 0.2rem;
}

.formField::-webkit-input-placeholder {
  color: lightgrey;
}

.formField::-ms-input-placeholder {
  color: lightgrey;
}

.formField::placeholder {
  color: lightgrey;
}

.formContent {
	width: 100%;
}

.formAdditionalText {
	width: 70%;
}

.shortForm {
	height: 1rem;
}


/*.popupXL .form {
	height: 32rem;
}

.popupLarge .form {
	height: 23rem;
}

.popupMedium .form {
	height: 18rem;
}


.popupSmall .form {
	height: 13rem;
}*/




.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;
	width: 70%;
}

.autocomplete .form {
	width: 100%;
}

.formItem {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.autocompleteField {
	width: 100%;
	border-radius: 1rem;
}

.autocompleteHolder {
	width: 100%;
	height: 4rem;
}

.signupContainer .formButtons {
	margin-top: 2rem;
}

.signupContainer .locationsSignup .formButtons {
	margin-top: -2rem;
}

.formButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: auto;
    margin-bottom: 1rem;
}

.formButton {
	width: 40%;
}

.singleFormButton {
	width: 100%;
	margin-bottom: 1rem;
}

.topButton {
	margin-bottom: 1rem;
}

/*
.singleFormButton,
.signup .formButtons, 
.passwordReset .formButtons, 
.passwordResetEmail .formButtons, 
.initialScreen.basicInfo .formButtons, 
.myProfHeaderButtons {
	justify-content: center;
}*/

/*Other Layout*/

/*map to display with main content*/
.savedLocationContainer .savedLocationRight {
	margin-top: 3.7rem;
}

.tripContainer .tripLocationsRight {
	margin-top: 2.8rem;
}

.attractionsContainer .attractionsRight {
	margin-top: 2.5rem;
}

.attractionContainer .attractionRight {
	margin-top: 2.1rem;
}

.overallLocationContainer .overallLocRight, 
.tripLocationContainer .tripLocAttrRight {
	margin-top: 4.5rem;
}

.attractionInfo {
	display: flex;
	margin-bottom: 0.2rem;
}

.icons, 
.labelAndTooltip, 
.removePicHolder,
.filterStars, 
.backAndHeader, 
.titleAndText {
	display: flex;
	align-items: center;
} 

.headerAndIcons {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 0.5rem;
}

.fullWidth {
	width: 100%;
}

.marginBottom {
	margin-bottom: 1rem;
}

.marginLeft {
	margin-left: 1rem;
}

.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.centerText {
	text-align: center;
}

.closeIconHolder {
	display: flex;
	justify-content: flex-end;
}

.restaurantIcons {
	white-space: nowrap;
}

.backIcon {
	margin-right: 0.5rem;
}

/*.tipTitle {
	width: 12rem;
}*/

.headerAndIcons .icons, 
.removePicHolder .fontXS {
	margin-left: 0.5rem;
}

.attractionBottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.attractionTop {
	display: flex; 
}

.pinAndNumber {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.userInfo, 
.timeframe {
	display: flex;
	align-items: center;
	margin-bottom: 0.3rem;
}

.userName {
	margin-left: 0.3rem;
}


.selectDropdown {
	padding-top: 0.5rem;
    max-height: 5rem;
    overflow-y: scroll;
    font-size: 0.8rem;
    padding: 0.3rem;
}

/*Search*/

.searchForm {
	display: flex;
}

.searchField {
	height: 1.5rem;
	width: 20rem;
	margin-right: 0.5rem;
}

.searchLeft {
	display: flex;
	flex-direction: column;
}

.searchToggleView {
	height: 0.8rem;
    display: flex;
    line-height: 0.8rem;
    margin-top: 0.2rem;
    font-size: 0.8rem;
    align-self: flex-end;
}
   
.searchButton {
	height: 2rem;
	white-space: nowrap;
	padding: 0.2rem;
	margin-right: 1rem;
} 

.switchTitle {
	margin-right: 0.5rem;
}

/*Messages*/
.messageContent {
	margin-left: 0.5rem;
}

.messageHeader {
	display: flex;
	margin-bottom: 0.2rem;
	align-items: center;
}

.messengerName {
	margin-right: 0.5rem;
}

.messageCreatedAt {
	margin-right: 1rem;
}


.locationTagHolder, 
.userTagHolder {
	display: flex;
	margin-right: 0.5rem;
}


.locationTagHolder .removeIcon, 
.userTagHolder .removeIcon {
	margin-left: 0.1rem;
}


.locationTag, 
.userTag, {
	background-color: #F9993A;
	opacity: 0.6;
	box-shadow: 5px 5px 5px grey;
	border-radius: 20px;
	padding: 0 0.2rem;
	display: inline-block;
/*	height: 1rem;*/
}

.attractionTagHolder {
	display: flex;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}


.attractionTag,
.pictureTag {
	background-color: #F9993A;
	opacity: 0.6;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    color: white;
    display: inline-block;
}

.createPictureTag .mainBackground {
	background-color: rgba(254, 223, 73, 0.4);
}

.pictureTag {
	display: flex;
}

.instagramAttribution {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
	font-size: 0.6rem;
}

.nameAndIcon {
	display: flex;
	align-items: center;
}

.loadingHolder {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.attractionTagHolder .removeIcon {
	margin-left: 0.1rem;
}


.attractionTagSmall {
	background-color: #F9993A;
	opacity: 0.6;
    border-radius: 5px;
    color: white;
	font-size: 0.7rem;
    padding: 0.1rem 0.2rem;
    display: inline-block;
    margin-left: 0.3rem;
}

.attractionTagsContent {
	display: flex;
	margin-bottom: 0.5rem;
}

.tooltip {
	all: unset;
	font-size: 0.8rem;
	padding: 0.2rem;
	border-radius: 5px;
	background-color: lightgreen;
	z-index: 5;
	/*position: absolute;*/
}

.pictureView .tooltipIcon, 
.generalLocTips .tooltipIcon {
   	margin-left: 0.3rem;
 }

label {
	all: unset;
}

.disabledPagination {
	opacity: 0;
}

.error, 
.formError {
	color: red;
}

.unread {
	color: #19A59B;
	font-weight: bold;
}

.textInput,
.filterInput {
	margin-bottom: 1rem;
	width: 100%;
	padding: 0.2rem;
	border-radius: 
}

.stars {
	white-space: nowrap;
}


.overallAttractionContainer .addToTripContainer {
	margin-left: 5rem;
}
.instagramAddSection {
	display: flex;
	width: 100%;
	margin-bottom: 1rem;
}
.logoIcon {
	height: 3rem;
	margin-left: 7rem;
	margin-top: 1rem;
	color: #105B57;
}

.landingPageMobile .logoIcon {
	margin-left: 0;
}


.landingPageNav {
	display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 4rem;
    padding-top: 1rem;
    margin-right: 2rem;
}

.landingPageMobile .landingPageNav {
	justify-content: center;
	margin-right: 0;
}

.landingPageMain {
	display: flex;
}




.landingPageNavFont {
	font-family: Inter;
	font-weight: 600;
	font-size: 16px;
	margin: 1rem;
	color: #105B57;

}


.landingPageButton {
	all: unset;
	background-color: #105B57;
	padding: 0.5rem 1rem;
    border-radius: 100px;
    color: white;
    margin: 1rem;
}


.landingPageSection {
	width: 100vw;
	height: 100vh;
	display: flex;
}

.landingPageMobile .landingPageSection {
	flex-direction: column;
	height: 100%;
}

.landingPageDesktop .landingPageSectionLeft, .landingPageDesktop .landingPageSectionRight {
	height: 100vh;
	width: 50%;
}

.landingPageMobile .landingPageSectionLeft, .landingPageMobile .landingPageSectionRight {
	display: flex; 
	flex-direction: column;
	justify-content: center;

}


.landingPageMainPic {
	height: 110vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.landingPageTextSection {
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding-left: 7rem;
    padding-right: 2rem;
}

.landingPageMobile .landingPageTextSection {
	padding-left: 1rem; 
	padding-right: 1rem;
	display: flex;
	align-items: center;
}

.landingPageTitle {
	font-size: 2.5rem;
	font-family: Inter;
	font-weight: 700;
	color: #18191F;
	line-height: 3.5rem;
}

.landingPageMobile .landingPageTitle {
	font-size: 1.5rem;
	line-height: 2rem;
	text-align: center;
}

.landingPageSubtext {
	font-size: 1.1rem;
	font:'Inter';
	color: #18191F;

}

.landingPageMobile .landingPageSubtext {
	text-align: center;
}

.landingPageForm {
	margin-top: 2rem;
	width: 100%;
}


.PhoneInputCountryIconImg {
	display: none;
}


.PhoneInputCountry {
	display: flex;
}

.PhoneInput {
	display: flex;
}

.PhoneInputInput, .PhoneInputCountrySelect {
	width: 100%;
}

.landingPage .autocompleteField, .PhoneInputInput, .PhoneInputCountrySelect, .landingPageField {
	background: rgba(191, 226, 220, 0.2);
    border-radius: 100px;
    border: 0;
    height: 3rem;
    padding: 0.5rem;
    color: #105B57;
    width: 100%;
    margin: 0.2rem;

}

.landingPage .autocompleteField::-webkit-input-placeholder, .PhoneInputInput::-webkit-input-placeholder, .landingPageField::-webkit-input-placeholder {
	color: #105B57;
}

.landingPage .autocompleteField::-ms-input-placeholder, .PhoneInputInput::-ms-input-placeholder, .landingPageField::-ms-input-placeholder {
	color: #105B57;
}

.landingPage .autocompleteField::placeholder, .PhoneInputInput::placeholder, .landingPageField::placeholder {
	color: #105B57;
}

.landingPage .formItem {
	height: 3rem;
	margin: 0;
}

.landingPageNames {
	display: flex;
	width: 100%;
}

.landingPageName {
	width: 100%;
}

.landingPageButtonHolder {
	display: flex;
	align-items: center;
	justify-content: center;
}

.landingPageButtonHolder .landingPageButton {
	font-size: 1.2rem;
	border-radius: 100px;
}

.landingPagePicture {
	width: 50vw;
	-o-object-fit: contain;
	   object-fit: contain;
}

.landingPageMobile .landingPagePicture {
	width: 100%;
}


.landingPagePictureSmall {

}

.landingPageIcon {
	height: 4rem;

}

.landingPageSectionBottom {
	background-color: #F2F9F8;
	align-items: center;
	justify-content: center;
}

.landingPageSectionBottom .landingPageTextSection {
	align-items: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
}

.landingPageMobile .landingPageSectionBottom .landingPageTextSection {
	width: 80%;
	margin-top: 2rem;
}

.landingPageSectionBottom .landingPageTitle {
	text-align: center;
}

.landingPageButtonLarge {
	padding: 1.5rem;
}
 .resumeMobileBackground {
   background-image: url(/static/media/van.0a6b74e2.png);
   background-size: cover;
   height: 50rem;
}

.resumeDesktopBackground {
   background-image: url(/static/media/van.0a6b74e2.png);
   background-size: cover;
   height: 100vh;
}

.resumeDesktopHolder {
   display: flex;
   padding:  4rem;
   padding-bottom: 0;

}

.topHolder {
   padding-top: 3rem;
   display: flex;
   flex-direction: column;
   align-items: center;
}


.textCenter {
   align-self: center;
   text-align: center;
   color: #18191F;
   width:  80%;
   font-weight: 500;
   font-family: Inter;
}

.leftHolder {
   width:  50%;
   margin-top:  1rem;
}

.logoIconLarge {
   height:  5rem;
}

.textLeft {
   font-size: 20px;
   padding-left: 1rem;
   color: #18191F;
}

.rightHolder {
   width:  50%;
   position: relative;
}

.circles {
   width: 100%;
   margin-left: 10%;
   margin-top: -5rem;
}
.textRightHolder {
   top:  0;
   left:  0;
}

.textRight {
  z-index: 2;
  position: absolute;
  top: 12%;
  left: 48%;
}

.resumeHeaderText {
    font-size: 2.5rem;
    font-family: Inter;
    font-weight: 600;
    color: #18191F;
    line-height: 3.5rem;
}

.resumeList {
   margin-left: -1rem;
}

.resumeListText {
   font-family:  Inter;
   color: #18191F;
   line-height: 1.8rem;
}

.resumeListTextSmall {
   font-family:  Inter;
   color: #18191F;
   font-size: 12px;
}

.resumeLink {
   color:  #105B57;
   font-weight: 600;
   font-family:  Inter;

}

.resumeOneLine: {
   display: flex;
}

.resumeEmailPass {
   margin-left: 1rem;
   color: #BFE2DC;
}

.iphoneImagesHolder {
   display: flex;
   z-index: 10;
   margin-top: 6rem;
   width: 120%;
}

.iphoneImage {
   width: 30%;
   z-index: 10;
}


.horizontalImagesHolder {
    overflow: auto;
   white-space: nowrap;
   margin-top: 2rem;
   margin-left: 1rem;
   margin-bottom: 1rem;
}

.iphoneImageMobile {
   width: 60%;
}

.bottomHolder {
   padding: 1rem;
   background-color: #BFE2DC;
   justify-content: center;
   align-items: center;
   display: flex;
   flex-direction: column;
}
.attraction {
	min-height: 4rem;
}



.attraction.mainBackground {
	padding: 0.5rem 1rem;
}

.attractionMain {
	display: flex;
}

.attractionContent {
	width: 70%;

}

.attraction .icons {
	margin-left: auto;
}

.readMore {
	display: inline;
}

.attraction .attractionRight {
 	width: 30%;
 	margin-left: 1rem;
 	margin-top: 2rem;
 	display: flex;
 	flex-direction: column;
 	justify-content: space-between;
}


/*.attraction .textTitle {
	width: 6rem;
}*/


.profileContainer .createMessageForm, 
.locationContainer .createMessageForm {
	margin-top: 5rem;
}


.messageUser {
	display: flex;
	align-items: center;
}

.createMessageForm .picMedium {
	margin-right: 1rem;
}


.createMessageHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

}

.createMessageForm .locationTag {
	height: 1.5rem;
}

.messageForm {
	margin-top: 1rem;
	width: 100%;
}

.messageForm .formField {
	width: 100%;
	height: 5rem;
}

.messageFormButtons {
	display: flex;
	justify-content: flex-end;
	margin-right: 1rem;
}

.messageForm .primaryButton {
	padding: 0.2rem 1rem;
}
.locationHeader {
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	margin-left: 2rem;
}

.locHeaderLeft {
	width: 70%;
	display: flex;
}

.locHeaderContent {
	display: flex;
	width: 100%;
	margin-left: 1rem;
	flex-direction: column;
	justify-content: center;
}

.locHeaderMain {
	display: flex;
	flex-direction: row;
}


.locHeaderRight {
	margin-left: 1rem;
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.headerMiddle {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}

.headerMiddleMain {
	width: 70%;
}






.followButton {
	display: flex;
}

.followButton .dropdown {
	width: 100%;
	margin-left: 0.5rem;
}

.followButton .dropdownToggle, 
.followButton .dropdownToggle:hover, 
.followButton .dropdownToggle:focus, 
.followButton .dropdownToggle:active {
	font-size: 2rem;
}

.followView .followButton {
	font-size: 0.7rem;
}
.datesContent {
	display: flex;
	align-items: center;
}

.datesContent .iconSmall {
	margin-left: 0.2rem;
}

.date {
	display: flex;
}

.dateContent {
	white-space: nowrap;
	display: flex;
	align-items: flex-end;
}

.startDateAndDash {
	display: flex;
}

.dash {
	vertical-align: center;
}

.dates {
	margin-right: 5rem;
}

.itineraryItemForm .dates {
	margin-top: 0.5rem;
}


.tipSection {
	margin-bottom: 0.5rem;
}

.locationTipsHeader {
	display: flex;
	align-items: center;
}

.locationTipsHeader .subHeader {
	margin-right: 1rem;
}

.navBar {
	height: 4rem;
	padding: 1rem 0rem 0.5rem 0rem;
	display: flex;
	box-shadow: 5px 0px 10px grey;
	background-color: #33CCCC;
}

.navBar .activeIcon {
	color: white;
}

.navBar .textLink {
	color: white;
}

.btn-group {
	color: white;
}

.leftColumnSpace {
	width: 10%;
}

.navMain {
	height: 2.5rem;
	overflow: hidden;
	width: 80%;
	display: flex;
	justify-content: space-between;
}

.navButtons {
	display: flex; 
	justify-content: space-between;
	align-items: center;
}

.navLinks {
	display: flex;
	justify-content: space-between;
	margin-right: 2rem;
	align-items: center;
}


.navIcon {
	margin-left: 1rem;
	display: flex;
	align-items: center;
}

.navLink, 
.navLink:hover, 
.navLink:focus {
	color: white;
	font-weight: bold;
	padding: 0.3rem;
	white-space: nowrap;
	margin-right: 0.5rem;
	text-decoration: none;
}


.navLink:hover {
	background-color: rgba(249, 153, 58, 0.6);
	border-radius: 5px;
}

.navLink:focus {
	background-color: rgba(249, 153, 58, 0.4);
	border-radius: 5px;
}

.navProf {
	display: flex;
	align-items: center;
}

.navBar .dropdown {
	margin-right: 0rem;
}

.navBar .dropdown, .navGuest {
	width: 10%;
	padding-right: 1rem;
}

.navBar .dropdownMenu {
	margin-left: -85%;
}

.settingsLink {
	all: unset;
}





.searchAutocomplete .error {
	margin-right: 0.5rem;
}

.searchToggleView {
	white-space: nowrap;
}
.allMessagesHeaderMain {
	display: flex;
	justify-content: space-between;
	margin-right: 1rem;
}

.allMessagesHeader {
	margin-bottom: 1rem;
}
.locationTagHolder {
    height: 1.2rem;
    margin-bottom: 0.5rem;
}

.message {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
}

.messageHeader {
	display: flex;
}


.messageReceived {
	color: black;
}

.allMessages {
	width: 100%;
}

.allAndUserMessages .allMessages {
	width: 30%;
}
.userMessages {
	height: 28rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}


.userMessagesContent {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;
}

/*with react scroll to bottom, my messages wouldnt display on right side without holder*/
.messageHolder{
	display: flex;
}

.myMessageHolder {
	justify-content: flex-end;
}
.notificationContent, 
.notificationContent:hover {
	display: flex;
	color: black;
	align-items: center;
	text-decoration: none;
}

.notificationMain {
	display: flex;
	align-items: center;
}


.notificationMiddle {
	margin-left: 1rem;
}

.navBarContainer .notification {
	font-size: 0.7rem;
}

.notificationText {
	margin-right: 0.2rem;

}

.notificationContainer .picMedium {
	margin-left: 2rem;
}

.notificationsContainer .squarePic {
	margin-left: 2rem;
}

.followButtons {
	display: flex;
    width: 10rem;
    justify-content: space-between;
    margin-left: 2rem;
    align-items: center;
}


.navBarContainer .notificationsHeader {
	margin-top: 0.5rem;
}

.notificationsHeader .notificationDot {
	margin-left: 0.5rem;
}
.welcome .primaryButton, 
.welcome .secondaryButton {
	font-size: 1.5rem;
	padding: 0.7rem;
}

.attrUserInfo {
	margin-right: 2rem;
	margin-left: 0.5rem;
}

.nameAndType {
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.reviewContent {
	display: flex;
}

.overallAttractionReviews .icons {
	align-self: flex-start;
}
.groupedAttractionsHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}


.overallLocationContainer .dropdownSection {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.savedLocationContainer .groupedAttractionsContent {
	margin-top: 0.5rem;
}

.groupedHeaderButtons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
	padding-right: 0.5rem;
}

/*make the tabs smaller to create space between since rendering at 50% of screen*/
.tripLocAttrItinerary .tabButtons {
	padding-right: 1rem;
	padding-left: 1rem;
}

.tripLocAttrItinerary .tabButton {
	width: 7rem;
}

.attractionsTable {
	overflow-y: scroll;
	height: 20rem;
	width: 100%;
}

.groupedAttraction {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 0.5rem;
}

.groupedAttractionIcons {
	display: flex;
	align-items: center;
}

.groupedAttractionLeft {
	display: flex;
	align-items: center;
}

.groupedAttrName {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	display: flex;
	flex-wrap: wrap;
}

.groupedAttractionsList .removeIcon {
	align-self: flex-start;
	margin-right: 0.5rem;
}

.itineraryItem {
	border: 1px dashed gray;
	padding: 0.5rem 1rem;
	cursor: move;
}

.itemTitle {
	font-weight: bold;
}
.tripVoteButtons {
	display: flex;
}

.tripVoteButton {
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.tip {
	margin-bottom: 0.5rem;
}

.generalLocTipsHeader {
	display: flex;
	justify-content: space-between;
}


.profileContainer {
	padding-top: 0.5rem;
}

.cropper-view-box {
	border-radius: 100%;
	outline: none;
}

.point-ne, 
.point-nw, 
.point-se, 
.point-sw {
	display: none;
}

.point-e, 
.point-n, 
.point-w, 
.point-s {
	background-color: black;
}


.cropper-bg {
	background-image: none;
/*	height: 15rem;
	width: 15rem;*/

}
/*
.cropper-crop-box {
	height: 15rem;
	width: 15rem;
}*/

.cropper-modal {
	background-color: white;
}

.picUploading {
	display: none;
}



.basicInfo .formButtons {
	 margin-top: 0.3rem;
}

.currentCityInfo {
	width: 100%;
}
.followView .mainBackground {
	padding: 0.5rem;
}

.follow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.followUserInfo {
	display: flex;
	align-items: center;
}

.followView .picSmall {
	margin-right: 1rem;
}

.locationListView .mainBackground {
	width: 100%
}

.profileHeader {
	display: flex;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	margin-left: 2rem;
}

.profHeaderLeft {
	width: 60%;
	display: flex;
}

.profHeaderContent {
	margin-left: 2rem;
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}


/*.profileHeader .primaryButton {
	height: 1.75rem;
	width: 6rem;
	font-size: 0.75rem;
	padding: 0;
}*/



.profPic {
	height: 10rem;
	width: 10rem;
	border-radius: 100%;
	position: relative;
}

.newProfPicButton:hover {
	font-size: 0.5rem;
	background: rgba(128,128,128, 0.6);
	margin: -2.75rem 1rem 0 1rem;
	color: white;
	position: absolute;
	height: 2rem;
	width: 6rem;
	text-align: center;
	vertical-align: middle;
	border-radius: 0 0 100% 100%;
	z-index: 2;

}


.newProfPicButton {
	font-size: 0.5rem;
	background: transparent;
	margin-top: -2.5rem;
	color: transparent;
	position: absolute;
	z-index: 2;
}


.profHeaderRight {
	width: 40%;
	margin-left: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.profHeaderNumbers {
	display: flex;
	justify-content: center;
	align-items: center;
}


.numberItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 1rem;
}

.number {
	font-weight: 700;
}

.numberType {
	font-size: 0.75rem;
}

.savedMapView {
	margin-top: 1rem;
}

.savedListView {
	margin-top: -1rem;
}

.savedFilter {
	display: flex;
	justify-content: flex-end;
}

.savedHeader .formField, 
.savedHeader .autocompleteField {
	width: 18rem;
}

.savedHeader .autocomplete {
	height: 2rem;
}



.pagination .textLink {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}


.search {
	margin-top: 4rem;
	align-self: center;
}

.searchFilters {
	width: 100%;	
	margin-bottom: 1rem;
}

.filtersContent {
	display: flex;
	justify-content: center;
}

.searchFiltersCollapsed {
	height: 12rem;
}

.filtersToggle {
	display: flex;
	align-items: center;
}

.filtersLabel {
	margin-right: 0.5rem;
}


.filterSection {
	margin-right: 1rem;
	display: flex;
    flex-direction: column;
    align-items: center;
}

/*without height and width, it won't wrap properly. NEED TO MAINTAIN PROPER HEIGHT BASED ON NUMBER OF TAGS*/
.tagSection {
	flex-flow: wrap column;
    display: flex;
    height: 7rem;
    width: 30rem;
}

.tagSectionCollapsed {
	
}

.tagTitle {
	display: flex;
	justify-content: center;
}

.tagSection .checkboxSection {
	width: unset;
	margin-right: 1rem;
}

.filterSection .textTitle {
	display: flex;
	justify-content: center;
	margin-bottom: 0.5rem
}

.andUpSection {
	justify-content: center;
}

.andUpSection .checkboxMedium {
	margin-right: 0.5rem;
}

.toggleFilterHolder {
	width: 100%;
	display: flex; 
	justify-content: center;
	margin-bottom: 1rem;
}
.searchHeader {
	margin-bottom: 2rem;
}

.searchHeaderMiddle {
	display: flex;
	justify-content: center;
}

.searchHeaderBottom {
	display: flex;
	justify-content: center;
}

.keyWordAndSort {
	display: flex;
	justify-content: space-around;
	align-items: center;

}

.searchHeader label{
	white-space: nowrap;
}

.searchHeader .iconMedium {
	margin-right: 0.5rem;
}

.keyWordSection {
	display: flex;
	margin-right: 1rem;
	display: flex;
	align-items: center;
	width: 100%;
}


.checkboxesAndFilters {
	margin-top: 1rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 50%;
}


.searchHeader .checkboxesSection {
	display: flex;
}

.searchHeader .checkboxSection {
	margin-right: 1rem;
}

.searchHeader .dropdownSection {
	display: flex;
}

.searchResults {
	display: flex;
}

.searchFull {
	width: 100%;
}

.userResult .searchResultLeft {
	display: flex;
}

.searchOverallLeft {
	margin-left: 5rem;
}

.searchResultRight {
	display: flex;
    flex-direction: column;
    align-items: center;
}

.attractionResult .searchResultRight {
	width: 30%;
	display: flex;
}

.searchResultBottom {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.searchResultLeftTop{
	display: flex;
}

.searchResultContent {
	display: flex;
	justify-content: space-between;
}

.attractionResult .searchResultRight .icons {
	margin-bottom: 0.5rem;
    width: 100%;
    justify-content: flex-end;
}

.searchResult .picMedium {
	margin-right: 1rem;
}

.searchResults .stars {
	margin-top: 0.3rem;
}

.starsHolder {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 0.5rem;
}

.searchResultHeaderMiddle {
	display: flex;
	align-items: center;
}

.searchResultHeaderMiddle .icons {
	margin-right: 0.2rem;
}

.searchResultHeaderMiddle .stars {
	margin-left: 0.5rem;
	margin-top: 0rem;
	display: flex;
	align-items: center;
}


.suggestedFollow {
	margin-top: 1rem;
}

.suggestedFollowContent {
	display: flex;
	overflow: scroll;
}

.suggestedFollow {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 8rem;
	
}

.suggestedFollowHolder {
	margin-right: 0.5rem;
}

.suggestedFollows .textLink {
	margin-top: 0.2rem; 
	margin-bottom: 0.2rem;
}



.settingsContent {
	display: flex;
    justify-content: center;
    margin-top: 2rem;

}



.settingsItem {
	display: flex;
	justify-content: space-between;

}

.settingsTitle {
	width: 50%;
}

.settingsValue {
	display: flex;
	align-items: center;
}

.settingsContainer .secondaryButton {
	margin-left: 1rem;
}


.publicityLabel {
	font-weight: bold;
	margin-right: 1rem;
}


.groupTripLocations .form {
	width: 100%;
}

.groupTripLocations .mainBackground {
	padding: 0.5rem;
}

.groupTripLocsContent {
	overflow-y: scroll;
	height: 15rem;
	width: 100%;
}

.groupTripLocations .formButtons {
	margin-top: 0.5rem;
}
.tripLocations .mainBackground {
	height: 18rem;
    overflow-y: scroll;
}

.tripLocationsHeader {
	margin-bottom: 0.5rem;
	display: flex;
	justify-content: space-between;
}

.tripLocation {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.5rem;
}

.tripLocationsTitle {
	display: flex; 
	justify-content: flex-end;
	margin-right: 4rem;
}

.locNameAndRemove {
	display: flex;
}

.tripLocations .removeIcon {
	align-self: flex-start;
    margin-top: 0.5rem;
    margin-right: 0.5rem;

}

.tripDates {
	display: flex;
}

.tripHeaderButtons {
	display: flex;
	align-items: center;
	margin-top: 0.2rem;
}

.tripHeader .editIcon {
	margin-left: 0.5rem;
}
.tripTransportationsContainer {
	margin-bottom: 2rem;
}
/*scroll is not ideal but this is a huge form and it renders fixed*/
.tripTransportationForm.popupXL {
	height: 38rem;
	overflow-y: scroll;
}

.addTripUser {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 1rem;
}

.tripTransportationForm .textTitle {
	margin-right: 1rem;
}

.userTagHolder {
	margin-bottom: 0.5rem;
}
.tripTransportationsHeader {
	display: flex;
	justify-content: space-between;
}

.transportationCategoryTitles {
	display: flex;
	justify-content: space-around;
}

.transportation {
	display: flex;
	justify-content: space-between;
}


.tripTransportationsButtons {
	display: flex;
	align-items: center;
	margin-right: 0.2rem;
}

.transportationButtons {
	display: flex;
	align-items: center;
}

.transportationItem, 
.transportationCategoryTitle {
	flex-basis: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.transportationUserInfo {
	display: flex;
	align-items: center;
	flex-direction: row;
}
.createTripUsers {
	margin-bottom: 1rem;
}



.tripAddedUsers {
	display: flex;
	margin-left: 4rem;
	flex-wrap: wrap;
	margin-bottom: 1rem;
} 

.createTripContainer .addTripUsers {
	display: flex;
	justify-content: space-between;

}

.tripContainer .addTripUsers {
	width: 100%;
	display: flex;
	justify-content: center;
}

.createTripUsers .textTitle {
	margin-right: 1rem;
	text-align: center;
}


.tripContainer .createTripUserInput {
	margin-bottom: 0.5rem;

}

.tripContainer .createUsersButtons {
	display: flex;
	justify-content: space-around;
	margin-top: auto;
}

.tripContainer .createTripUsersContent {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-self: center;
}

.tripUsersHeader {
	display: flex;
	justify-content: space-between;
}

.tripUsersButtons {
	display: flex;
	align-items: center;
}

.tripUserInfo {
	display: flex;
	align-items: center;
}


.tripUserName {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
}


.exportItinerary {
	display: flex; 
	justify-content: space-between;
}
.locItinerary {
	min-height: 30rem;
}
.locItineraryHeader {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
}


.itineraryTable {
	height: 46rem;
}

.locItinerary .mainBackground {
	height: 100%;
	overflow-y: scroll;
}

.locItinerary .tabButtons {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}

.tabAndArrow {
	display: flex;
	justify-content: space-between;
	width: 10rem;
}

.itineraryEditButtons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.5rem;
}
.itineraryDropZone {
	width: 100%;
	height: 2rem;
}

.lastZone {
	height: 10rem;
}

.switchSection {
	align-items: flex-start;
}

.itineraryItemForm {
	height: 38rem;
	overflow-y: scroll;
}

.tripItineraryDays {
	display: flex;
	flex-flow: wrap;
}

.tripItineraryDay {
	min-height: 16rem;
	width: 14%;
	margin-right: 0.25%;
}

.tripItineraryItem {
	margin-bottom: 0.3rem;
}

.tripItinDate {
	margin-bottom: 0.5rem;
}

.tripItineraryButtons {
	display: flex;
	justify-content: space-between;
}

.tripItinButtons {
	display: flex;
	align-items: center;
	flex-basis: 100%;
}

.tripItinButtonsRight {
	justify-content: flex-end;
	margin-right: 2rem;
}

.tripItinItemButtons {
	display: flex;
}

.tripItineraryDay .textTitle {
	white-space: normal;
	white-space: initial;
	word-wrap: break-word;
}


.tripItineraryHeaderContent {
	display: flex;
	justify-content: space-between;
	margin-right: 1rem;
	margin-bottom: 1rem;
}

.tripHeaderContent {
	display: flex;
}
.tripAddedLocations {
	display: flex;
	margin-left: 4rem;
	flex-wrap: wrap;
} 

.addTripLocations {
	display: flex;
	justify-content: space-between;

}

.createTripLocations .textTitle {
	margin-right: 1rem;
	margin-top: 1rem;
	text-align: center;
}

/*This is to account for autocomplete being a form*/
.createTripLocations .form {
	height: 4rem;
} 


.myTripsHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}
.tripLocationContainer .toggleButtons {
	margin-top: 1rem;
}

/*.tripLocationHeader .secondaryButton {
	height: 1.5rem;
    width: 4rem;
   
}*/
 
.locItineraryHeader .secondaryButton {
	height: 1.5rem;
    padding: 0rem;
    text-align: center;
    vertical-align: middle;
    margin-right: 0.5rem;
}


.waitListBackground {
	background-image: url(/static/media/backgroundPic.804e48f9.png);
	background-size: cover;
	height: 100vh;
}

.waitingListContainer {
	height: 100%;
	width: 100%;
}

.waitListMain {
	display: flex;
	justify-content: center;
}
.waitListTitleSection {
	margin-top: 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 2rem;
	margin-left: 2rem;

}

.waitListTopText {
	margin-top: 3rem;
}

.waitListTitleWord {
	font-size: 3.5rem;
	font-weight: bold;
	color: #181415;
}

.waitListTitleText {
	font-size: 1.5rem;
	width: 15rem;
	text-align: center;
	color: #181415;
}

.waitListTitleTextMobile {
	font-weight: 700;
	font-size: 2rem;
}

.waitListForm {
	display: flex;
    flex-direction: column;
    margin-right: 30%;
    margin-left: 30%;
    margin-top: 5%;
}

.waitListFormMobile {
	display: flex;
    flex-direction: column;
   	margin-right: 2%;
   	margin-left: 2%;
    margin-top: 5%;
}

.waitListFormRefer {
	display: flex;
    flex-direction: column;
    margin-right: 30%;
    margin-left: 30%;
    margin-top: 2%;
}


.waitListFormReferMobile {
	display: flex;
    flex-direction: column;
    margin-right: 2%;
    margin-left: 2%;
    margin-top: 2%;
}

.waitListFormField {
    width: 100%;
    border: none;
    padding: 0.2rem;

}

.waitListFormError {
	display: flex;
	justify-content: center;
	color: #8E1600;
}

.waitListFormField:focus, .autocompleteField:focus {
	outline: none;
}

.waitingList .autocompleteField {

    width: 100%;
    border: none;
    padding: 0.2rem;
    border-radius: 0;
    margin-bottom: 1rem;

}

.waitingListButton {
	all: unset;
	background-color: #19A59B;
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	color: white;
	font-weight: bold;
}
.waitListLogoHolder {
	display: flex;
	justify-content: center;
	    margin-bottom: 2rem;
}

.waitListLogoMobileHolder {
	display: flex;
	justify-content: center;
	align-items: center;
    margin-bottom: 2rem;
}

.waitListLogo {
	height: 100%;
	width: 10%;
}

.waitListLogoMobile {
	height: 100%;
	width: 10%;
	margin-top: 5rem;
}

.waitListLogoMobile {
	width: 50%;
}

.waitListItem {
	margin-bottom: 0.5rem;
}

.waitListNamesMain {
	display: flex;

}

.waitListMainMobile {
	display: flex; 
	flex-direction: column;
	align-items: center;
}


.waitListNames {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
}

.waitListNameLeft {
	margin-right: 0.2rem;
	width: 50%;
}


.waitListNameRight {
	margin-left: 0.2rem;
	width: 50%;
}

.waitListExtraText {
	align-self: center;
}


.waitListSubTextSection {
	display: flex; 
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 2rem;
}

.waitListSubText {
	color: #19A59B;
	font-size: 2rem;
	font-weight: 700;
}

.waitListSmallSubText {
	color: #19A59B;
	font-size: 1.2rem;
	font-weight: 500;
}









