
.navBar {
	height: 4rem;
	padding: 1rem 0rem 0.5rem 0rem;
	display: flex;
	box-shadow: 5px 0px 10px grey;
	background-color: #33CCCC;
}

.navBar .activeIcon {
	color: white;
}

.navBar .textLink {
	color: white;
}

.btn-group {
	color: white;
}

.leftColumnSpace {
	width: 10%;
}

.navMain {
	height: 2.5rem;
	overflow: hidden;
	width: 80%;
	display: flex;
	justify-content: space-between;
}

.navButtons {
	display: flex; 
	justify-content: space-between;
	align-items: center;
}

.navLinks {
	display: flex;
	justify-content: space-between;
	margin-right: 2rem;
	align-items: center;
}


.navIcon {
	margin-left: 1rem;
	display: flex;
	align-items: center;
}

.navLink, 
.navLink:hover, 
.navLink:focus {
	color: white;
	font-weight: bold;
	padding: 0.3rem;
	white-space: nowrap;
	margin-right: 0.5rem;
	text-decoration: none;
}


.navLink:hover {
	background-color: rgba(249, 153, 58, 0.6);
	border-radius: 5px;
}

.navLink:focus {
	background-color: rgba(249, 153, 58, 0.4);
	border-radius: 5px;
}

.navProf {
	display: flex;
	align-items: center;
}

.navBar .dropdown {
	margin-right: 0rem;
}

.navBar .dropdown, .navGuest {
	width: 10%;
	padding-right: 1rem;
}

.navBar .dropdownMenu {
	margin-left: -85%;
}

.settingsLink {
	all: unset;
}




